import { createContext, useContext, useEffect, useState } from 'react';
import {
  AuthenticatedMonolithUser,
  AuthenticatedMonolithUserOperator,
  AuthenticatedMonolithUserShipper,
  AuthenticatedMonolithUserWithoutLegacyUserRole,
} from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';
import { useCognito } from 'src/hooks/use-cognito';
import { useCognitoUser } from 'src/hooks/use-cognito-user';
import { getCognitoToken } from 'src/utils/axios-instance';
import { Forbidden, Unauthorized } from 'src/utils/errors';
import { NoContent } from 'src/utils/no-content';
import { StrictOmit } from 'src/utils/utility-types';

const url = `${PRISMA_CONFIG.monolithApiUrl}/auth`;

/**
 * Note that not all users are known to the monolith! You can *still* be authenticated
 * to other services via Cognito.
 */
export type EnhancedAuthenticatedMonolithUser =
  // shared fields:
  {
    /**
     * The user is either currently acting as a shipper or _could_ switch to the shipper role.
     */
    hasShipper: boolean;
    /**
     * The user is either currently acting as a TSO or _could_ switch to the TSO role.
     */
    hasTso: boolean;
    /**
     * The user is either currently acting as a SSO or _could_ switch to the SSO role.
     */
    hasSso: boolean;
    /**
     * The user is either currently acting as a TSO/SSO or _could_ switch to the TSO/SSO role.
     */
    hasOperator: boolean;
  } & ( // role specific fields:
    | (StrictOmit<AuthenticatedMonolithUserOperator, 'role'> & {
        role: 'SSO_ADMIN';
        isOperator: true;
        isTso: false;
        isSso: true;
        isShipper: false;
      })
    | (StrictOmit<AuthenticatedMonolithUserOperator, 'role'> & {
        role: 'TSO_USER';
        isOperator: true;
        isTso: true;
        isSso: false;
        isShipper: false;
      })
    | (AuthenticatedMonolithUserShipper & {
        isOperator: false;
        isTso: false;
        isSso: false;
        isShipper: true;
      })
    | (AuthenticatedMonolithUserWithoutLegacyUserRole & {
        isOperator: false;
        isTso: false;
        isSso: false;
        isShipper: false;
      })
  );

export type AuthenticatedMonolithUserContextValue =
  EnhancedAuthenticatedMonolithUser | null;

const AuthenticatedMonolithUserContext =
  createContext<AuthenticatedMonolithUserContextValue>(null);

/**
 * This hook returns the user that is currently authenticated in the monolith.
 * You'll get an `Unauthenticated` error, if there is no authenticated user.
 *
 * Note that not all users are known to the monolith! You can *still* be authenticated
 * to other services via Cognito.
 */
export function useAuthenticatedMonolithUser() {
  const context = useContext(AuthenticatedMonolithUserContext);
  const cognitoUser = useCognitoUser();

  if (context === null) {
    if (cognitoUser)
      throw new Forbidden(); // logged in with Cognito, but not known in the monolith
    else throw new Unauthorized();
  }

  return context;
}

/**
 * This hook returns the user that is currently authenticated in the monolith.
 * `null` will be returned, if there is no authenticated user.
 *
 * Note that not all users are known to the monolith! You can *still* be authenticated
 * to other services via Cognito.
 */
export function useOptionalAuthenticatedMonolithUser() {
  return useContext(AuthenticatedMonolithUserContext);
}

export function useAuthenticatedMonolithUserRequest() {
  const [catched, setCatched] = useState(false);
  const cognito = useCognito();

  const user = useAxios(
    async (axios, baseConfig) => {
      // we don't want to be authenticated _only_ in cognito, but not the monolith
      // (except for users that are not known to the monolith).
      // therefor we _always_ try to refresh the cognito token before we call `/auth`
      await getCognitoToken(cognito);

      const auth = await axios.request<AuthenticatedMonolithUser | NoContent>({
        ...baseConfig,
        url,
        // if the old platform is too slow to respond (>= 10s) a timeout
        // error will be thrown - that way we default to unauthenticated behavior
        // to get at least a working app for every user who wouldn't want to
        // authenticate anyway (and users who want to authenticate would need
        // to use the login)
        timeout: 10000,
      });

      return auth;
    },
    {
      neededOnPageLoad: true,
      onError() {
        // Special error treatment!
        // This request is done very early in the life cycle of the app.
        // We can't really recover from it as there is nothing in place
        // to recover to. But if we return `null` here, the app at least
        // _starts_ the same as for any unauthenticated user.
        // In case the user just want's to do something where authentication
        // is not needed he/she can happily continue.
        setCatched(true);
      },
    }
  );

  useEffect(() => {
    user.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resolved = Boolean(user.response) || catched;

  const value = enhanceMonolithUser(
    // if we aren't authenticated, we get "204 No Content" -> empty string
    user.response && user.response.data !== '' ? user.response.data : undefined
  );

  return [resolved, value] as const;
}

export function enhanceMonolithUser(user?: AuthenticatedMonolithUser) {
  if (!user) return null;

  const isTso = user.role === 'TSO_USER';
  const isSso = user.role === 'SSO_ADMIN';
  const isOperator = isTso || isSso;
  const isShipper =
    user.role === 'SHIPPER_USER' || user.role === 'SHIPPER_ADMIN';
  const hasShipper = isShipper || user.hasShipperRole;
  const hasTso = isOperator || user.originalRole === 'TSO_USER';
  const hasSso = isOperator || user.originalRole === 'SSO_ADMIN';
  const hasOperator = hasTso || hasSso;
  return {
    ...user,
    isOperator,
    isTso,
    isSso,
    isShipper,
    hasShipper,
    hasTso,
    hasSso,
    hasOperator,
  } as EnhancedAuthenticatedMonolithUser;
}

export const AuthenticatedMonolithUserProvider =
  AuthenticatedMonolithUserContext.Provider;
