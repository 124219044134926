import { z } from 'zod';

/**
 * Common form option schema used by our select and checkbox group components.
 */
export const formOptionSchema = z.object({
  label: z.string(),
  value: z.string(),
});

/**
 * Common form options schema used by our tables and other collections.
 */
export const descSortDirectionSchema = z.enum(['asc', 'desc']).default('desc');

/**
 * Common form options schema used by our tables and other collections.
 */
export const ascSortDirectionSchema = z.enum(['asc', 'desc']).default('asc');

/**
 * Common pagination schema used by our pagination component.
 */
export const getPaginationSchema = ({
  pageSize = 10,
}: { pageSize?: number } = {}) =>
  z.object({
    start: z.coerce.number().default(0),
    pageSize: z.coerce.number().default(pageSize),
  });
