import { FC, lazy } from 'react';
import { ContractReportsParams } from 'src/apis/aggregate-eu/types';
import { AggregateEuRootRequest } from 'src/apis/aggregate-eu/use-aggregate-eu-root';
import { useGetContractReports } from 'src/apis/aggregate-eu/use-get-contract-reports';
import { useGetLocations } from 'src/apis/aggregate-eu/use-get-locations';
import { PageSpinner } from 'src/components/spinner-container';
import { Successful } from 'src/hooks/use-axios';
import { usePageParams } from 'src/pages/aggregate-eu/contract-reports/schema';
import { useMemoOne } from 'use-memo-one';

export const Page = lazy(() => import('./page'));

export const AggregateEuContractReports: FC<{
  aggregateEuRootRequest: Successful<AggregateEuRootRequest>;
}> = ({ aggregateEuRootRequest }) => {
  const pageParams = usePageParams();
  const showOwnContracts = Boolean(
    pageParams.value.buyers.find((value) => value === 'myOwn')
  );
  const filteredBuyerValues = pageParams.value.buyers.filter(
    (value) => value !== 'myOwn'
  );

  const params = useMemoOne<ContractReportsParams>(() => {
    const { pageSize, locationId, start, ...rest } = pageParams.value;

    return {
      offset: start,
      limit: pageSize,
      locationId: [...pageParams.value.locationId],
      showOwnContracts,
      consortiumId: filteredBuyerValues,
      ...rest,
    };
  }, [pageParams.value]);

  const contracts = useGetContractReports({
    params,
    contractsLink: aggregateEuRootRequest.response.data._links?.contractReports,
  });

  const locations = useGetLocations({
    aggregateEuRootRequest,
  });

  if (!contracts.response || !locations.response) return <PageSpinner />;
  return (
    <Page
      pageParams={pageParams}
      showOwnContracts={showOwnContracts}
      filteredBuyerValues={filteredBuyerValues}
      contracts={contracts}
      locations={locations}
    />
  );
};
