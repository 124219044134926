import { FC, lazy } from 'react';
import { Navigate, Route, useLocation, useParams } from 'react-router-dom';
import { AggregateEuRootRequest } from 'src/apis/aggregate-eu/use-aggregate-eu-root';
import { TransactionsParams } from 'src/apis/capacity-management/types';
import { OrganisationServiceRequest } from 'src/apis/organisation-service/use-organisation-service';
import { NetworkPointRedirect } from 'src/components/domain-specifics/network-points/network-point-redirect';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { AutoFullwidth } from 'src/components/layout/auto-fullwidth';
import { Stack } from 'src/components/layout/stack';
import { RoleGateway } from 'src/components/role-gateway';
import { RootRoutes } from 'src/components/root-routes';
import { Successful } from 'src/hooks/use-axios';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { stringifySearchParams } from 'src/hooks/use-page-params-from-schema';
import { useStorybook } from 'src/hooks/use-storybook';
import { AggregateEu } from 'src/pages/aggregate-eu';
import { Dashboard } from 'src/pages/dashboard';
import { Debug } from 'src/pages/debug';
import { DeveloperTools } from 'src/pages/developer-tools';
import { useDeveloperTools } from 'src/pages/developer-tools/use-developer-tools';
import { DirectFcfs } from 'src/pages/direct-fcfs';
import { Gdm } from 'src/pages/gdm';
import { Lng } from 'src/pages/lng';
import { Login } from 'src/pages/login';
import { Logout } from 'src/pages/logout';
import { NewNetworkPointSearch } from 'src/pages/new-network-point-search';
import { Operators } from 'src/pages/operators';
import { FinancialSecurityAmend } from 'src/pages/operators/assignments/details/financial-securities/amend';
import { FinancialSecurityRelease } from 'src/pages/operators/assignments/details/financial-securities/release';
import { UserInvitation } from 'src/pages/organisation/users/invitation';
import { Platform } from 'src/pages/platform';
import { Registration } from 'src/pages/registration';
import { ReportingAuctions } from 'src/pages/reporting/auctions';
import { ReportingAuction } from 'src/pages/reporting/auctions/details';
import { ReportingBalancingGroupAllocations } from 'src/pages/reporting/balancing-group-allocations';
import { BgaRedirect } from 'src/pages/reporting/balancing-group-allocations/balancing-group-route-redirect';
import { ReportingBooking } from 'src/pages/reporting/direct-fcfs/bookings';
import { ReportingLngRoutes } from 'src/pages/reporting/lng';
import { MonthlyStatements } from 'src/pages/reporting/monthly-statements';
import { MonthlyStatementRedirect } from 'src/pages/reporting/monthly-statements/monthly-statement-redirect';
import { ReportingTransactions } from 'src/pages/reporting/my-transactions';
import { DealAllocationToBalancingGroups } from 'src/pages/reporting/my-transactions/deals/allocation-to-balancing-groups';
import { CreateConversion } from 'src/pages/reporting/my-transactions/deals/conversions/create';
import { ConversionDetail } from 'src/pages/reporting/my-transactions/deals/conversions/details';
import { MultiYearTransactionDetail } from 'src/pages/reporting/my-transactions/multi-year';
import { MultiYearAllocationToBalancingGroups } from 'src/pages/reporting/my-transactions/multi-year/allocation-to-balancing-groups';
import { TradeAllocationToBalancingGroups } from 'src/pages/reporting/my-transactions/trades/allocation-to-balancing-groups';
import { ReportingUploads } from 'src/pages/reporting/products';
import { FcfsBookingDetails } from 'src/pages/reporting/regular-fcfs/details';
import { FcfsBookingDetailsByBookingId } from 'src/pages/reporting/regular-fcfs/details/by-booking-id';
import { ReportingPrimaryRemitReports } from 'src/pages/reporting/remit-reports';
import { ReportingSecondaryTradings } from 'src/pages/reporting/secondary-tradings/overview';
import { ReportingSecondaryTradeProposal } from 'src/pages/reporting/secondary-tradings/trade-proposal';
import { ShipperTransactions } from 'src/pages/reporting/shipper-transactions';
import { ReportingStandard } from 'src/pages/reporting/standard';
import { CreateSurrenderRequest } from 'src/pages/reporting/surrender/create';
import { SurrenderRequestDetail } from 'src/pages/reporting/surrender/detail';
import { SurrenderOverview } from 'src/pages/reporting/surrender/overview';
import { Transparency } from 'src/pages/reporting/transparency';
import { ProfileSettingsRedirect, Settings } from 'src/pages/settings';
import { ShippersView } from 'src/pages/shippers';
import { ShipperDetails } from 'src/pages/shippers/details';
import { EditShipperDocument } from 'src/pages/shippers/documents/edit-document';
import { TsoInvoiceDetail } from 'src/pages/shippers/invoices/detail';
import { UploadDocumentsToShippers } from 'src/pages/shippers/upload-documents';
import { SnamBidCollectorBiddingPage } from 'src/pages/snam-bid-collector/bidding';
import { SnamBiddingSuccessPage } from 'src/pages/snam-bid-collector/bidding/success';
import { SnamBidCollectorSettingsPage } from 'src/pages/snam-bid-collector/settings';
import { Start } from 'src/pages/start';
import { StorageLocationsOverview } from 'src/pages/storage/locations';
import { StorageOffers } from 'src/pages/storage/offers';
import { AuctionsStart } from 'src/pages/transport/auctions';
import { CreateComfortBids } from 'src/pages/transport/auctions/comfort-bids/create';
import { EditComfortBids } from 'src/pages/transport/auctions/comfort-bids/edit';
import { AuctionDetailPage } from 'src/pages/transport/auctions/details';
import { RunningAuctionsOverview } from 'src/pages/transport/auctions/overview';
import { BundleRequestsOverview } from 'src/pages/transport/bundle-requests/overview';
import { FcfsBooking } from 'src/pages/transport/fcfs-booking';
import { NetworkPoint } from 'src/pages/transport/network-points/details';
import { NetworkPointsOverview } from 'src/pages/transport/network-points/overview';
import { NetworkPointsRedirect } from 'src/pages/transport/network-points/redirect';
import { SecondaryTradingOverview } from 'src/pages/transport/secondary-tradings/overview';
import { TransportSecondaryTradingsTrade } from 'src/pages/transport/secondary-tradings/trade';
import { UrgentMarketMessages } from 'src/pages/umm';

const UnknownError = lazy(() => import('src/pages/errors/unknown'));

const MarketInformation = lazy(() => import('src/pages/market-information'));

type Props = {
  aggregateEuRootRequest: AggregateEuRootRequest;
  organisationService: Successful<OrganisationServiceRequest>;
};

const ReportingRedirect: FC = () => {
  const monolithUser = useOptionalAuthenticatedMonolithUser();

  if (monolithUser?.isTso) {
    return <Navigate replace to="/reporting/auctions" />;
  } else {
    return <Navigate replace to="/reporting/standard" />;
  }
};

export const OurRoutes: FC = () => {
  const developerTools = useDeveloperTools();
  const { isStorybook } = useStorybook();
  return (
    <RootRoutes>
      <Route
        path="/platform"
        element={
          isStorybook ? (
            <p>
              This is an Angular page. This page is <strong>not</strong>{' '}
              documentend in Storybook.
            </p>
          ) : (
            <Platform />
          )
        }
      />

      <Route path="/login/*" element={<Login />} />

      <Route path="/logout/*" element={<Logout />} />

      <Route path="/registration/*" element={<Registration />} />

      <Route
        path="/organisation/users/invitation/:invitationId/*"
        element={<UserInvitation />}
      />

      <Route
        path="/network-points/:oldNetworkPointId/*"
        element={<RedirectOldNetworkPointPages />}
      />

      <Route path="/umm/*" element={<UrgentMarketMessages />} />

      <Route
        path="/mail-redirect/surrender-service/surrender-request/:surrenderRequestId/*"
        element={<RedirectSurrender />}
      />

      <Route
        path="/mail-redirect/capacity-management/shipper-missing-bga-for-deal/:dealId/:direction/*"
        element={<RedirectDealAllocationToBGA />}
      />

      <Route
        path="/mail-redirect/capacity-management/shipper-missing-bga-for-trade/:tradeId/:direction/*"
        element={<RedirectTradeAllocationToBGA />}
      />

      <Route
        path="/mail-redirect/capacity-management/bga-overview/:nwpUuid/:balancingGroupId/:balancingGroupName/:allocationIntervalIntersectionStart/:allocationIntervalIntersectionEnd/*"
        element={<BgaRedirect />}
      />

      <Route
        path="/mail-redirect/capacity-management/conversion/:conversionId/*"
        element={<RedirectConversion />}
      />

      <Route
        path="/reporting/*"
        element={
          <>
            <AutoFullwidth />
            <RootRoutes>
              <Route path="/reporting/tr/*" element={<Transparency />} />
              <Route
                path="/reporting/monthly-statements/*"
                element={<MonthlyStatements />}
              />
              <Route
                path="/reporting/balancing-group-allocations/*"
                element={<ReportingBalancingGroupAllocations />}
              />
              <Route
                path="/reporting/shipper-transactions/conversions/:conversionRequestId/*"
                element={<ConversionDetail />}
              />
              <Route
                path="/reporting/shipper-transactions/multi-year/details/:multiYearAllocationId/*"
                element={<MultiYearTransactionDetail />}
              />
              <Route
                path="/reporting/shipper-transactions/*"
                element={<ShipperTransactions />}
              />
              <Route
                path="/reporting/products/*"
                element={<ReportingUploads />}
              />
              <Route
                path="/reporting/standard/*"
                element={<ReportingStandard />}
              />
              <Route
                path="/reporting/surrenders"
                element={<SurrenderOverview />}
              />
              <Route
                path="/reporting/auctions/details/:auctionId/*"
                element={<ReportingAuction />}
              />
              {[
                '/reporting/auctions',
                '/reporting/auctions/short-and-long-term-auctions',
                '/reporting/auctions/pending-auctions',
                '/reporting/auctions/reverse-auctions',
              ].map((path) => (
                <Route key={path} path={path} element={<ReportingAuctions />} />
              ))}
              <Route
                path="/reporting/secondary/proposals/:proposalId/*"
                element={<ReportingSecondaryTradeProposal />}
              />
              <Route
                path="/reporting/secondary/trades/:tradeId/*"
                element={<TransportSecondaryTradingsTrade />}
              />
              <Route
                path="/reporting/secondary/*"
                element={<ReportingSecondaryTradings />}
              />
              <Route
                path="/reporting/remit-reports/*"
                element={<ReportingPrimaryRemitReports />}
              />
              <Route
                path="/reporting/my-transactions/multi-year/details/:multiYearAllocationId/*"
                element={<MultiYearTransactionDetail />}
              />
              <Route
                path="/reporting/my-transactions/deals/:dealId/conversions/create/*"
                element={<CreateConversion />}
              />
              <Route
                path="/reporting/my-transactions/deals/:dealId/allocation-to-balancing-groups/*"
                element={<DealAllocationToBalancingGroups />}
              />
              <Route
                path="/reporting/my-transactions/trades/:id/allocation-to-balancing-groups/*"
                element={<TradeAllocationToBalancingGroups />}
              />
              <Route
                path="/reporting/my-transactions/multi-year/:multiYearAllocationId/allocation-to-balancing-groups/*"
                element={<MultiYearAllocationToBalancingGroups />}
              />
              <Route
                path="/reporting/my-transactions/conversions/:conversionRequestId/*"
                element={<ConversionDetail />}
              />
              <Route
                path="/reporting/my-transactions/*"
                element={<ReportingTransactions />}
              />
              <Route
                path="/reporting/surrenders/create/:transactionId/*"
                element={<CreateSurrenderRequest />}
              />
              <Route
                path="/reporting/surrenders/:surrenderRequestId/*"
                element={<SurrenderRequestDetail />}
              />
              <Route
                path="/reporting/fcfs"
                element={
                  <Navigate
                    replace
                    to={{
                      pathname: '/reporting/my-transactions',
                      search: stringifySearchParams({
                        transactionType: ['FCFS'],
                      } satisfies TransactionsParams),
                    }}
                  />
                }
              />
              <Route
                path="/reporting/my-transactions/direct-fcfs/bookings/:bookingId/*"
                element={<ReportingBooking />}
              />
              <Route
                path="/reporting/my-transactions/regular-fcfs/details/:bookingProcessId"
                element={<FcfsBookingDetails />}
              />
              <Route
                path="/reporting/my-transactions/regular-fcfs/details-by-booking-id/:bookingId"
                element={<FcfsBookingDetailsByBookingId />}
              />
              <Route
                path="/reporting/shipper-transactions/regular-fcfs/details/:bookingProcessId"
                element={<FcfsBookingDetails />}
              />
              <Route
                path="/reporting/shipper-transactions/regular-fcfs/details-by-booking-id/:bookingId"
                element={<FcfsBookingDetailsByBookingId />}
              />
              <Route path="/reporting/lng/*" element={<ReportingLngRoutes />} />
              <Route path="/reporting" element={<ReportingRedirect />} />
              <Route path="*" element={<ThrowNotFound />} />
            </RootRoutes>
          </>
        }
      />

      <Route
        path="/snam-bid-collector/*"
        element={<SnamBidCollectorSettingsPage />}
      />
      <Route path="/snam-bidding" element={<SnamBidCollectorBiddingPage />} />
      <Route
        path="/snam-bidding/success"
        element={<SnamBiddingSuccessPage />}
      />

      <Route path="/settings/*" element={<Settings />} />
      <Route path="/profile-settings/*" element={<ProfileSettingsRedirect />} />

      <Route
        path="/profile"
        element={<Navigate replace to="/profile-settings/profile-settings" />}
      />

      <Route path="/dashboard" element={<Dashboard />} />

      <Route
        path="/mail-redirect/capacity-management/tso-new-invoice-created-for-monthly-statement/:monthlyStatementId/*"
        element={<MonthlyStatementRedirect />}
      />

      <Route
        path="/mail-redirect/capacity-management/tso-new-invoice-created/:invoiceId/*"
        element={<RedirectShippersInvoice />}
      />

      <Route
        path="/shippers/invoices/:invoiceId/*"
        element={<TsoInvoiceDetail />}
      />

      <Route
        path="/shippers/details/:shipperRefId/admins/*"
        element={<RedirectShipperAdmins />}
      />

      <Route
        path="/shippers/details/:shipperRefId/*"
        element={<RedirectShipper />}
      />

      <Route
        path="/shippers/documents/:operatorDocumentId/*"
        element={
          <RoleGateway allowed={['TSO', 'SSO']}>
            <EditShipperDocument />
          </RoleGateway>
        }
      />

      {[
        '/shippers/list/*',
        '/shippers/documents/*',
        '/shippers/invoices/*',
        '/shippers/credit-limit/*',
        '/shippers/organisations-and-users/*',
      ].map((path) => (
        <Route key={path} path={path} element={<ShippersView />} />
      ))}

      <Route
        path="/shippers"
        element={<Navigate replace to="/shippers/organisations-and-users" />}
      />

      <Route
        path="/shippers/organisations"
        element={<Navigate replace to="/shippers/organisations-and-users" />}
      />

      {/* Redirects users from the old /shippers/users page to the new one (in case they have it bookmarked) */}
      <Route
        path="/shippers/users"
        element={<Navigate replace to="/shippers/organisations-and-users" />}
      />

      <Route
        path="/shippers/upload-documents/*"
        element={
          <RoleGateway allowed={['TSO', 'SSO']}>
            <UploadDocumentsToShippers />
          </RoleGateway>
        }
      />

      {[
        '/shippers/:shipperRefId/*',
        '/mail/shippers/:shipperRefId/*',
        '/dashboard/shippers/:shipperUuid/*',
      ].map((path) => (
        <Route key={path} path={path} element={<ShipperDetails />} />
      ))}

      <Route
        path="/shipper/*"
        element={
          <RoleGateway allowed={['TSO']}>
            <ShippersView />
          </RoleGateway>
        }
      />

      <Route path="/lng/*" element={<Lng />} />

      <Route path="/storage/locations" element={<StorageLocationsOverview />} />

      <Route
        path="/dashboard/operators/assignments/:operatorUuid/financial-securities/cash-deposit/:financialSecurityId/release/*"
        element={<FinancialSecurityRelease type="CASH_DEPOSIT" />}
      />
      <Route
        path="/dashboard/operators/assignments/:operatorUuid/financial-securities/cash-deposit/:financialSecurityId/amend/*"
        element={<FinancialSecurityAmend type="CASH_DEPOSIT" />}
      />
      <Route
        path="/dashboard/operators/assignments/:operatorUuid/financial-securities/rating/:financialSecurityId/amend/*"
        element={<FinancialSecurityAmend type="RATING" />}
      />
      <Route
        path="/dashboard/operators/assignments/:operatorUuid/financial-securities/bank-guarantee/:financialSecurityId/amend/*"
        element={<FinancialSecurityAmend type="BANK_GUARANTEE" />}
      />
      <Route
        path="/dashboard/operators/assignments/:operatorUuid/financial-securities/parent-company-guarantee/:financialSecurityId/amend/*"
        element={<FinancialSecurityAmend type="PARENT_COMPANY_GUARANTEE" />}
      />

      {['/operators/*', '/dashboard/operators/*'].map((path) => (
        <Route key={path} path={path} element={<Operators />} />
      ))}

      <Route
        path="/mail-redirect/capacity-management/shipper-new-invoice-document-available/:invoiceId/*"
        element={<RedirectOperatorsInvoice />}
      />

      <Route
        path="/mail-redirect/capacity-management/shipper-invoice-cancelled/:invoiceId/*"
        element={<RedirectOperatorsInvoice />}
      />

      <Route
        path="/mail-redirect/capacity-management/shipper-new-invoice-available/:invoiceId/*"
        element={<RedirectOperatorsInvoice />}
      />

      <Route
        path="/assignments/*"
        element={<Navigate replace to="/operators/assignments" />}
      />

      <Route
        path="/operator-documents/*"
        element={<Navigate replace to="/operators/operator-documents" />}
      />

      <Route
        path="/transport/secondary/*"
        element={<SecondaryTradingOverview />}
      />

      <Route
        path="/transport/secondary/trades/:tradeId/*"
        element={<TransportSecondaryTradingsTrade />}
      />

      <Route
        path="/transport/secondary/proposals/:proposalId/*"
        element={<ReportingSecondaryTradeProposal />}
      />

      <Route
        path="/transport/network-points"
        element={<NetworkPointsOverview />}
      />

      <Route
        path="/transport/network-points/redirect/:networkPointId"
        element={<NetworkPointsRedirect />}
      />

      <Route
        path="/transport/network-points/:oldNetworkPointId/*"
        element={<NetworkPoint />}
      />

      <Route
        path="/network-points-redirect/:networkPointUuid/*"
        element={<NetworkPointRedirect />}
      />

      <Route path="/transport/auctions" element={<AuctionsStart />} />

      <Route
        path="/transport/auctions/comfort-bids/create/*"
        element={<CreateComfortBids />}
      />

      <Route
        path="/transport/auctions/comfort-bids/edit/:bidId"
        element={<EditComfortBids />}
      />

      <Route
        path="/transport/auctions/list"
        element={<RunningAuctionsOverview />}
      />

      {[
        '/transport/auctions/:auctionId',
        '/transport/auctions/:auctionId/:orderId',
      ].map((path) => (
        <Route key={path} path={path} element={<AuctionDetailPage />} />
      ))}

      <Route path="/transport/fcfs-booking/*" element={<FcfsBooking />} />

      <Route
        path="/transport/bundle-requests/*"
        element={<BundleRequestsOverview />}
      />

      <Route path="/storage/offers" element={<StorageOffers />} />

      <Route path="/direct-fcfs/*" element={<DirectFcfs />} />

      <Route path="/market-information/*" element={<MarketInformation />} />

      <Route path="/gdm/*" element={<Gdm />} />

      <Route path="/aggregate-eu/*" element={<AggregateEu />} />

      <Route
        path="/new-network-point-search"
        element={<NewNetworkPointSearch />}
      />

      <Route path="/error/*" element={<UnknownError />} />

      {developerTools && (
        <Route path="/developer-tools/*" element={<DeveloperTools />} />
      )}

      {PRISMA_CONFIG.stage === 'storybook' && (
        <Route path="/debug/*" element={<Debug />} />
      )}

      <Route
        path="/"
        element={
          PRISMA_CONFIG.stage === 'local' &&
          !PRISMA_CONFIG.angularUrl.includes('localhost:4200') ? (
            <Stack gap={1}>
              <p>Hello Developer!</p>

              <p>
                This is the root page of the React frontend. Normally we
                redirect the user to the Angular start page at this point, but
                it looks like you're running only the React frontend locally, so
                we keep you on this page.
              </p>
              <p>
                If you need the Angular frontend as well you can run{' '}
                <code>$ pnpm start:with-angular</code> instead of{' '}
                <code>$ pnpm start</code>.
              </p>

              <p>Happy coding!</p>
            </Stack>
          ) : (
            <Start />
          )
        }
      />

      <Route path="*" element={<ThrowNotFound />} />
    </RootRoutes>
  );
};

const RedirectOldNetworkPointPages: FC = () => {
  const { oldNetworkPointId } = useParams<{ oldNetworkPointId: string }>();
  const { pathname } = useLocation();
  const to = pathname.replace(
    `/network-points/${oldNetworkPointId}/`,
    `/transport/network-points/${oldNetworkPointId}/`
  );
  return <Navigate replace to={to} />;
};

const RedirectSurrender: FC = () => {
  const { surrenderRequestId } = useParams<{ surrenderRequestId: string }>();
  return (
    <Navigate replace to={`/reporting/surrenders/${surrenderRequestId}`} />
  );
};

const RedirectConversion: FC = () => {
  const { conversionId } = useParams<{ conversionId: string }>();
  return (
    <Navigate
      replace
      to={`/reporting/my-transactions/conversions/${conversionId}`}
    />
  );
};

const RedirectShippersInvoice: FC = () => {
  const { invoiceId } = useParams<{ invoiceId: string }>();
  return <Navigate replace to={`/shippers/invoices/${invoiceId}`} />;
};

const RedirectOperatorsInvoice: FC = () => {
  const { invoiceId } = useParams<{ invoiceId: string }>();
  return <Navigate replace to={`/operators/invoices/${invoiceId}`} />;
};

const RedirectShipperAdmins: FC = () => {
  const { shipperRefId } = useParams<{ shipperRefId: string }>();
  return <Navigate replace to={`/shippers/${shipperRefId}/admins`} />;
};

const RedirectShipper: FC = () => {
  const { shipperRefId } = useParams<{ shipperRefId: string }>();
  return <Navigate replace to={`/shippers/${shipperRefId}`} />;
};

const RedirectDealAllocationToBGA: FC = () => {
  const { dealId, direction } = useParams<{
    dealId: string;
    direction: string;
  }>();
  return (
    <Navigate
      replace
      to={`/reporting/my-transactions/deals/${dealId}/allocation-to-balancing-groups/${direction}`}
    />
  );
};

const RedirectTradeAllocationToBGA: FC = () => {
  const { tradeId, direction } = useParams<{
    tradeId: string;
    direction: string;
  }>();
  return (
    <Navigate
      replace
      to={`/reporting/my-transactions/trades/${tradeId}/allocation-to-balancing-groups/${direction}`}
    />
  );
};
