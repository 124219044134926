import { useEffect } from 'react';
import { Tso } from 'src/apis/monolith/types';
import { isServerError, useAxios } from 'src/hooks/use-axios';
import { NotFound } from 'src/utils/errors';

export type CommunicationInfo = {
  phone?: string;
  phoneBackup?: string;
  phoneOnCall?: string;
  mobile?: string;
  fax?: string;
  email?: string;
};

export type ContactType =
  | 'DISPATCHING'
  | 'BILLING'
  | 'COMMUNICATION'
  | 'OPERATION';

export type ContactPerson = {
  id: number;
  type?: ContactType;
  salutation?: 'MR' | 'MRS';
  title?: string;
  section?: string;
  firstName?: string;
  lastName?: string;
  communication?: CommunicationInfo;
};

export function useTso(id: string | number) {
  const tso = useAxios(
    (axios, baseConfig, id: string | number) =>
      axios.request<Tso>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/tso/${id}`,
        method: 'GET',
      }),
    {
      neededOnPageLoad: true,
      onError(error) {
        // sadly we get a 204 in case of an unknown id
        // see https://prisma.atlassian.net/browse/ISR-1819?focusedCommentId=127331
        if (isServerError(error, 204)) {
          throw new NotFound();
        } else {
          throw error;
        }
      },
    }
  );

  useEffect(() => {
    tso.execute(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return tso;
}

export type TsoRequest = ReturnType<typeof useTso>;
