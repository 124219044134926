import { directionValues } from 'src/components/data-display/direction';
import { networkPointSuggestionSchema } from 'src/components/domain-specifics/network-points/nwp-multi-select';
import { getPaginationSchema } from 'src/components/form/zod-schemas';
import {
  preprocessAsArray,
  preprocessAsBoolean,
} from 'src/components/form/zod-utilities';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import { tsoSuggestionSchema } from 'src/pages/reporting/my-transactions/tso-suggestions-input';
import {
  flowUnitValues,
  productPeriodTypeValues,
  salesMethodValues,
} from 'src/pages/transport/network-points/overview/utils';
import { z } from 'zod';

const getSchema = ({
  customQuickFilterDefault,
}: {
  customQuickFilterDefault: string | null;
}) =>
  z
    .object({
      networkPoint: z.preprocess(
        preprocessAsArray,
        z.array(networkPointSuggestionSchema).default([])
      ),
      tso: z.preprocess(
        preprocessAsArray,
        z.array(tsoSuggestionSchema).default([])
      ),
      customQuickFilter: z
        .string()
        .nullable()
        .default(customQuickFilterDefault),
      salesMethod: z.enum(salesMethodValues).nullable().default(null),
      productPeriodType: z.preprocess(
        preprocessAsArray,
        z.array(z.enum(productPeriodTypeValues)).default([])
      ),
      flowUnit: z.enum(flowUnitValues).nullable().default(null),
      direction: z.preprocess(
        preprocessAsArray,
        z.array(z.enum(directionValues)).default([])
      ),
      marketAreaFilter: z.preprocess(
        preprocessAsArray,
        z.array(z.string()).default([])
      ),
      capacityCategoryFilter: z.preprocess(
        preprocessAsArray,
        z.array(z.string()).default([])
      ),
      sortColumn: z.string().default('name'),
      sortAscending: z.preprocess(
        preprocessAsBoolean,
        z.boolean().default(true)
      ),
    })
    .merge(getPaginationSchema());

export function usePageParams() {
  const monolithUser = useOptionalAuthenticatedMonolithUser();
  return usePageParamsFromSchema(
    getSchema({
      customQuickFilterDefault: monolithUser?.isShipper
        ? '__showFavoritesOnly'
        : monolithUser?.isTso
          ? '__showOwnTsoOnly'
          : null,
    }),
    {
      filters: {
        networkPoint: { label: 'Network Point' },
        tso: { label: 'TSO' },
        customQuickFilter: { label: 'Quick Filter' },
        salesMethod: { label: 'Sales Method' },
        productPeriodType: { label: 'Available Product Types' },
        flowUnit: { label: 'Flow Unit' },
        direction: { label: 'Direction' },
        marketAreaFilter: { label: 'Market Area' },
        capacityCategoryFilter: { label: 'Capacity Category' },
      },
    }
  );
}

export type PageParams = ReturnType<typeof usePageParams>;

export type SearchParams = PageParams['value'];
