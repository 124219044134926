import { shipperDocumentsSortByValues } from 'src/apis/document-exchange/mappings';
import { documentTypeValues } from 'src/apis/document-exchange/types';
import {
  descSortDirectionSchema,
  getPaginationSchema,
} from 'src/components/form/zod-schemas';
import { preprocessAsArray } from 'src/components/form/zod-utilities';
import { documentStatusValues } from 'src/pages/shippers/documents/status';
import { z } from 'zod';

export const getDocumentsPageParamsSchema = ({
  defaultStartDate,
}: {
  defaultStartDate: string;
}) => {
  return z
    .object({
      sortBy: z.enum(shipperDocumentsSortByValues).default('CHANGED_AT'),
      sortDirection: descSortDirectionSchema,
      documentStatus: z.preprocess(
        preprocessAsArray,
        z.array(z.enum(documentStatusValues)).default([])
      ),
      documentType: z.preprocess(
        preprocessAsArray,
        z.array(z.enum(documentTypeValues)).default([])
      ),
      documentName: z.string().nullable().default(null),
      documentDescription: z.string().nullable().default(null),
      changedAtStart: z.string().nullable().default(defaultStartDate),
      changedAtEnd: z.string().nullable().default(null),
    })
    .merge(getPaginationSchema());
};
