import { FC, lazy } from 'react';
import { useAssignmentServicePublic } from 'src/apis/assignment-service/use-assignment-service-root';
import { useOldUserId } from 'src/apis/monolith/use-get-old-user-id';
import { PageSpinner } from 'src/components/spinner-container';
import { useAssignmentsOverviewPageParams } from 'src/pages/operators/assignments/overview/use-overview-params';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(() => import('./page'));

export const monolithAssignmentEndpoints = {
  getAssignmentsOfShipperUser: `${PRISMA_CONFIG.monolithApiUrl}/shippers/{customerUserRefId}/assigned-operators?detailLevel=EXTENDED_PUBLIC`,
  getAllTsos: `${PRISMA_CONFIG.monolithApiUrl}/tso`,
};

export const AssignmentsOverview: FC = () => {
  const oldUserId = useOldUserId();
  const pageParams = useAssignmentsOverviewPageParams();
  const assignmentService = useAssignmentServicePublic();

  if (!oldUserId.response || !assignmentService.response)
    return <PageSpinner />;

  // not a shipper user
  if (oldUserId.response.data.customerRefId === undefined)
    throw new Forbidden();

  const customerRefId = oldUserId.response.data.customerRefId;
  const customerUserId = oldUserId.response.data.customerUserId;
  const customerUserRefId = oldUserId.response.data.customerUserRefId;

  return (
    <Page
      customerRefId={customerRefId}
      customerUserId={customerUserId}
      customerUserRefId={customerUserRefId}
      pageParams={pageParams}
      assignmentService={assignmentService}
    />
  );
};
