import { productTypeValues } from 'src/apis/monolith/mappings';
import { getPaginationSchema } from 'src/components/form/zod-schemas';
import {
  preprocessAsArray,
  preprocessAsBoolean,
} from 'src/components/form/zod-utilities';
import { z } from 'zod';

const quickFilterValues = [
  'ALL',
  'DRAFT',
  'PENDING',
  'OPEN_AND_PUBLISHED',
  'WAITING_FOR_NEXT_ROUND',
  'FINISHED',
] as const;

export type QuickFilterValues = (typeof quickFilterValues)[number];

export const storageOffersPageParamsSchema = z
  .object({
    sortColumn: z.string().default('changedAt'),
    sortAscending: z.preprocess(
      preprocessAsBoolean,
      z.boolean().default(false)
    ),
    id: z.string().nullable().default(null),
    offerLabel: z.preprocess(
      preprocessAsArray,
      z.array(z.string()).default([])
    ),
    storageLocationName: z.preprocess(
      preprocessAsArray,
      z.array(z.string()).default([])
    ),
    tsoShortName: z.preprocess(
      preprocessAsArray,
      z.array(z.string()).default([])
    ),
    productType: z.preprocess(
      preprocessAsArray,
      z.array(z.enum(productTypeValues)).default([])
    ),
    runtimeFrom: z.string().nullable().default(null),
    runtimeTo: z.string().nullable().default(null),
    quickFilter: z.enum(quickFilterValues).default('OPEN_AND_PUBLISHED'),
  })
  .merge(getPaginationSchema());
