import reachCheckboxCss from '@reach/checkbox/styles.css?inline';
import reachDialogCss from '@reach/dialog/styles.css?inline';
import { fontStyle } from 'src/assets/fonts/fonts';
import logoSrc from 'src/assets/prisma-logo-short-colored.svg';
import {
  LayoutFooter,
  LayoutHeader,
  LayoutMain,
} from 'src/components/layout/layout';
import normalizeCss from 'src/normalize.css?inline';
import { createGlobalStyle, css, keyframes } from 'styled-components';

// eslint-disable-next-line no-restricted-syntax
export enum Colors {
  brand = '#00378b',
  brandLight1 = '#4069a8',
  /**
   * Used for placeholder and empty style color.
   */
  brandLight2 = '#809bc5',
  brandLight3 = '#c6e4f2',
  brandLight4 = '#deedf7',
  brandLight5 = '#f7fafd',
  brandSkyBlue = '#56a7d8',
  brandSecondary = '#ce2f87',
  brandBorder = '#cce4f3',
  error = '#ea1313',
  errorLight = '#ffa8a8',
  errorLighter = '#fff6f6',
  errorDark = '#E41111',
  inactive = '#8d9093',
  inactiveLight = '#e5e5e5',
  inactiveLighter = '#f9f9f9',
  ready = '#0ebfcb',
  readyLight = '#a6e7ec',
  success = '#00c878',
  successLight = '#a1ebcd',
  warning = '#ffbe1e',
  warningLight = '#fff8e4',
  background = '#ebf2fb',
}

export const flyoutStyles = css`
  background-color: #fff;
  border-radius: 0.4rem;
  box-shadow: 0 0.3rem 0.6rem #00378b33;
  background-clip: padding-box;
`;

// eslint-disable-next-line no-restricted-syntax
export enum Breakpoints {
  minTablet = 'min-width: 768px',
  minDesktop = 'min-width: 992px',
  minWideDesktop = 'min-width: 1200px',
}

// from https://dev.to/hankchizljaw/a-modern-css-reset-6p3
const modernReset = css`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  ul[class]:not(.sbdocs),
  ol[class]:not(.sbdocs) {
    padding: 0;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  p,
  ul[class]:not(.sbdocs),
  ol[class]:not(.sbdocs),
  li,
  figure,
  figcaption,
  blockquote,
  dl,
  dd {
    margin: 0;
  }

  body {
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
  }

  ul[class]:not(.sbdocs),
  ol[class]:not(.sbdocs) {
    list-style: none;
  }
`;

const testStyles = css`
  * {
    transition: none !important;
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${normalizeCss};
  ${reachDialogCss};
  ${reachCheckboxCss};
  ${modernReset};
  ${fontStyle};

  html {
    font-family: 'Roboto';
    /* Set font size to 62.5% so 1rem = 10px in most browsers */
    font-size: 62.5%;
    line-height: 1.42857143;
    color: ${Colors.brand};
    /* avoid layout jumping, because of scroll bars */
    /* but only on bigger screens to avoid non-centered content */
    /* https://stackoverflow.com/a/44000322/1337972 */
    @media (${Breakpoints.minTablet}) {
      width: 100vw;
      overflow-x: hidden;
    }
  }

  body {
    font-size: 1.5rem;
  }

  ${process.env.NODE_ENV === 'test' ? testStyles : null};
`;

export const PrintStyle = createGlobalStyle`
  @media print {
    @page {
      margin-top: 1em;
      margin-bottom: 1em;
    }

    ${LayoutHeader}, ${LayoutFooter} {
      display: none;
    }

    /* Logo in top-right corner */
    ${LayoutMain}::before {
      content: url("${logoSrc}");
      position: absolute;
      top: 0.5em;
      right: 0.5em;
      width: 8em;
      height: auto;
      z-index: 10;
    }

    ${LayoutMain} {
      width: 100%;
      box-sizing: border-box;
      margin-top: 1em;
      page-break-inside: avoid;
    }
  }
`;

// not sure who came up with this, but libs like font-awesome and
// ant-design use this for all their icons and it works wonderfully.
// it creates nicely centered icons when they are placed next to text.
export const centeredIconOffset = '0.125em';

export const rotateKeyframes = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const rotateAnimationDurationInMS = 800;

export const rotateAnimation = css`
  animation: ${rotateKeyframes} ${rotateAnimationDurationInMS}ms linear infinite;
`;

/**
 * This style emulates the default focus style of the browser.
 * Use it if you need custom focus handling.
 */
export const focusStyle = css`
  outline: currentColor auto 1px; /* for firefox */
  outline: -webkit-focus-ring-color auto 1px; /* for others */
`;

/**
 * Sometimes we have disabled items that are still accessible. In those
 * cases we use this style.
 */
export const disabledFocusStyle = css`
  outline: ${Colors.inactiveLight} auto 1px;
`;
