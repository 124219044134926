import { FC, useEffect, useState } from 'react';
import {
  PremiumServicesDebtor,
  ServicePackage,
  ShipperBriefInfo,
  ShipperPremiumServices,
} from 'src/apis/monolith/types';
import useGenerateInitialToken from 'src/apis/monolith/use-generate-initial-token';
import useGenerateToken from 'src/apis/monolith/use-generate-token';
import { Button } from 'src/components/buttons-and-actions/button';
import { Toggle } from 'src/components/buttons-and-actions/toggle';
import { Card } from 'src/components/data-display/card';
import { FormatNumber } from 'src/components/data-display/format-number';
import { SimpleTextarea } from 'src/components/form/textarea';
import { Stack } from 'src/components/layout/stack';
import { ExternalLink } from 'src/components/navigation/link';
import { Heading } from 'src/components/text/heading';
import { useBreakpoints } from 'src/hooks/use-breakpoints';
import { useModal2 } from 'src/hooks/use-modal';
import DowngradePrismaApiModal from 'src/pages/settings/premium-services/downgrade-prisma-api-modal';
import PrismaApiWizardModal from 'src/pages/settings/premium-services/prisma-api-wizard-modal';
import {
  ProvideSEPAMandateStepData,
  SelectServicePackageStepData,
} from 'src/pages/settings/premium-services/prisma-api-wizard-model';
import ReadOnlyOption from 'src/pages/settings/premium-services/read-only-option';

const ServicePackagesCard: FC<{
  servicePackages: ServicePackage[];
  shipperPremiumServices: ShipperPremiumServices | null;
  referenceId: number;
  shipperBriefInfo: ShipperBriefInfo;
  debtor: PremiumServicesDebtor;
}> = ({
  servicePackages,
  shipperPremiumServices,
  referenceId,
  shipperBriefInfo,
  debtor,
}) => {
  const { minDesktop } = useBreakpoints();
  const generateToken = useGenerateToken();
  const generateInitialToken = useGenerateInitialToken();
  const [token, setToken] = useState('');

  const [effectiveServicePackage, setEffectiveServicePackage] = useState(
    shipperPremiumServices?.servicePackage
  );
  const effectiveApiEnabled = effectiveServicePackage !== undefined;

  const [
    overrideSelectServicePackageStepData,
    setOverrideSelectServicePackageStepData,
  ] = useState<SelectServicePackageStepData>();
  const [
    overrideProvideSEPAMandateStepData,
    setOverrideProvideSEPAMandateStepData,
  ] = useState<ProvideSEPAMandateStepData>();
  const [overrideActiveStep, setOverrideActiveStep] = useState<number>();

  const downgradePrismaApiModal = useModal2({
    modal: ({ close }) => <DowngradePrismaApiModal onDismiss={close} />,
    trigger: () => null,
  });

  const prismaApiWizardModal = useModal2({
    modal: ({ close }) => (
      <PrismaApiWizardModal
        servicePackages={servicePackages}
        shipperBriefInfo={shipperBriefInfo}
        debtor={debtor}
        referenceId={referenceId}
        overrideActiveStep={overrideActiveStep}
        overrideSelectServicePackageStepData={
          overrideSelectServicePackageStepData
        }
        overrideProvideSEPAMandateStepData={overrideProvideSEPAMandateStepData}
        onDismiss={() => {
          setOverrideSelectServicePackageStepData(undefined);
          setOverrideActiveStep(undefined);
          close();
        }}
        onOrderCompleted={(servicePackage) => {
          setEffectiveServicePackage(servicePackage);
          generateInitialToken.execute({ id: referenceId, enabled: true });
        }}
        onFinished={() => {
          setOverrideSelectServicePackageStepData(undefined);
          setOverrideActiveStep(undefined);
          close();
        }}
      />
    ),
    trigger: () => null,
  });

  useEffect(() => {
    if (generateToken.response) {
      setToken(generateToken.response.data.token);
    }

    if (generateInitialToken.response) {
      setToken(generateInitialToken.response.data.token);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateToken.response, generateInitialToken.response]);

  return (
    <Card>
      <Stack gap={2}>
        <Stack gap={1} templateColumns={minDesktop ? '4fr 2fr' : '1fr'}>
          <div style={{ order: minDesktop ? 2 : 1, textAlign: 'right' }}>
            <Toggle
              data-testid="prisma-api-toggle"
              label=""
              isChecked={effectiveApiEnabled}
              onClick={() => {
                if (effectiveApiEnabled) {
                  downgradePrismaApiModal.open();
                } else {
                  prismaApiWizardModal.open();
                }
              }}
            />
          </div>
          <div style={{ order: minDesktop ? 1 : 2 }}>
            <Heading mode="card">PRISMA API</Heading>
          </div>
        </Stack>
        {effectiveApiEnabled ? (
          <>
            <Stack data-testid="service-packages" gap={2}>
              {servicePackages.map((servicePackage) => {
                return (
                  <ReadOnlyOption
                    key={servicePackage.identifier}
                    testId={servicePackage.identifier}
                    selected={
                      effectiveServicePackage.identifier ===
                      servicePackage.identifier
                    }
                    disabled={
                      servicePackage.worth <= effectiveServicePackage.worth
                    }
                    onClick={() => {
                      // upgrade only
                      if (
                        servicePackage.worth > effectiveServicePackage.worth
                      ) {
                        setOverrideSelectServicePackageStepData({
                          servicePackage,
                        });
                        setOverrideProvideSEPAMandateStepData({
                          formData: {
                            bic: debtor.bic,
                            companyName: shipperBriefInfo.name,
                            vatNumber: shipperBriefInfo.vatNumber,
                            debtorCity: debtor.city,
                            debtorCountry: debtor.country,
                            debtorStreet: debtor.street,
                            debtorZipCode: debtor.zip,
                            iban: debtor.iban,
                          },
                          confirmed: true,
                        });
                        setOverrideActiveStep(3);
                        prismaApiWizardModal.open();
                      }
                    }}
                  >
                    <Stack templateColumns={minDesktop ? '1fr 1fr 5fr' : '1fr'}>
                      <div style={{ order: 1 }}>{servicePackage.label}</div>
                      <div style={{ order: 2, textAlign: 'right' }}>
                        <FormatNumber value={servicePackage.price.value} />{' '}
                        {servicePackage.unit}
                      </div>
                    </Stack>
                  </ReadOnlyOption>
                );
              })}
            </Stack>
            <Stack gap={1}>
              <Heading mode="sub-section">Secure Token</Heading>
              <SimpleTextarea
                data-testid="token"
                readOnly
                disabled
                placeholder="A secure token has already been generated and is still active. In case you would like to deactivate the existing token and generate a new one, click on 'Generate token'."
                value={token}
                rows={5}
              />
              <p>
                Please note: We will only show you the secure token once when
                you set up the service for the first time. This is for security
                reasons. Please make sure you provide this token to your
                colleagues that implement the IT interface.
              </p>
              <p>You can generate a new token at any time.</p>
              <Stack gap={1} flow="column" textAlign="right">
                <div>
                  <Button
                    disabled={token === ''}
                    onClick={() => navigator.clipboard.writeText(token)}
                  >
                    Copy to clipboard
                  </Button>
                  &nbsp;
                  <Button
                    data-testid="generate-token"
                    onClick={() => generateToken.execute(referenceId)}
                  >
                    Generate Token
                  </Button>
                </div>
              </Stack>
            </Stack>
          </>
        ) : (
          <p>
            Enable for connecting your backend system with the PRISMA
            platform.&nbsp;
            <ExternalLink
              mode="default-underlined"
              href="https://help.prisma-capacity.eu/solution/articles/36000130389-activation-of-prisma-shipper-api"
              target="_blank"
            >
              See documentation
            </ExternalLink>
          </p>
        )}
      </Stack>
      {downgradePrismaApiModal.modal}
      {prismaApiWizardModal.modal}
    </Card>
  );
};

export default ServicePackagesCard;
