import {
  descSortDirectionSchema,
  formOptionSchema,
  getPaginationSchema,
} from 'src/components/form/zod-schemas';
import { preprocessAsArray } from 'src/components/form/zod-utilities';
import { correctionTypeLabels } from 'src/pages/reporting/monthly-statements/corrections/labels';
import { labelsToValues } from 'src/utils/labels-to-values';
import { z } from 'zod';

export const correctionTypeValues = labelsToValues(correctionTypeLabels);

export const correctionsMonthlyStatementsSchema = z
  .object({
    creationDateStart: z.string().nullable().default(null),
    creationDateEnd: z.string().nullable().default(null),
    billingPeriodStart: z.string().nullable().default(null),
    billingPeriodEnd: z.string().nullable().default(null),
    type: z.preprocess(
      preprocessAsArray,
      z.array(z.enum(correctionTypeValues)).default([])
    ),
    shipperId: z.preprocess(
      preprocessAsArray,
      z.array(formOptionSchema.merge(z.object({ eic: z.string() }))).default([])
    ),
    contractId: z.preprocess(
      preprocessAsArray,
      z
        .array(formOptionSchema.merge(z.object({ shipperName: z.string() })))
        .default([])
    ),
    sortBy: z
      .enum(['CREATION_DATE', 'BILLING_PERIOD_START'])
      .default('CREATION_DATE'),
    sortDirection: descSortDirectionSchema,
  })
  .merge(getPaginationSchema());
