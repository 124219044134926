import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { useAssignmentServicePublic } from 'src/apis/assignment-service/use-assignment-service-root';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { RootRoutes } from 'src/components/root-routes';
import { PageSpinner } from 'src/components/spinner-container';

const OperatorsListOverviewPage = lazy(() => import('./overview'));
const ApplyForNewAssignment = lazy(() => import('./apply-for-assignment'));
const SuccessfullyApplied = lazy(
  () => import('./apply-for-assignment/successfully-applied')
);

export const OperatorsListOverview = () => {
  const assignmentService = useAssignmentServicePublic();

  if (!assignmentService.response) return <PageSpinner />;
  return (
    <RootRoutes>
      <Route
        path="/operators/operator-assignments/overview/*"
        element={
          <OperatorsListOverviewPage assignmentService={assignmentService} />
        }
      />
      <Route
        path="/operators/operator-assignments/apply-for-assignment"
        element={
          <ApplyForNewAssignment assignmentService={assignmentService} />
        }
      />
      <Route
        path="/operators/operator-assignments/apply-for-assignment/successful"
        element={<SuccessfullyApplied />}
      />

      <Route
        path="/operators/operator-assignments"
        element={
          <Navigate replace to="/operators/operator-assignments/overview/" />
        }
      />

      <Route path="*" element={<ThrowNotFound />} />
    </RootRoutes>
  );
};
