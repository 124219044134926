// flag if the config fields represents a service
// (this is needed as we currently mix services and other settings in our global config)
const serviceConfig = {
  stage: false,
  monolithUrl: false,
  angularUrl: false,
  monolithApiUrl: true,
  organisationService: true,
  iipService: true,
  organisationRegistration: true,
  capacityManagement: true,
  authentication: false,
  surrenderService: true,
  contractManagement: true,
  documentExchange: true,
  gasDataManagement: true,
  snamBidCollector: true,
  transparencyReporting: true,
  directFcfs: true,
  regularFcfs: true,
  remitReporting: true,
  lngOperators: false,
  aggregateEu: true,
  networkPoints: true,
  authorizationService: true,
  lngMarketing: true,
  assignmentService: true,
} satisfies Record<keyof typeof PRISMA_CONFIG, boolean>;

type ServiceConfig = typeof serviceConfig;
type ServiceConfigKeys = keyof ServiceConfig;

export type Service = {
  [K in ServiceConfigKeys]: ServiceConfig[K] extends true ? K : never;
}[ServiceConfigKeys];

export const services = Object.entries(serviceConfig).reduce(
  (acc, [key, value]) => (value ? [...acc, key as Service] : acc),
  [] as Service[]
);
