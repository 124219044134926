import {
  faChevronCircleRight,
  faChevronCircleDown,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { flatten } from 'lodash';
import { FC, Fragment, ReactNode, useState } from 'react';
import { Button, ButtonProps } from 'src/components/buttons-and-actions/button';
import { Card } from 'src/components/data-display/card';
import {
  EmptyCard,
  EmptyCardProps,
} from 'src/components/data-display/empty-card';
import {
  SimpleTable,
  Tbody,
  TbodyProps,
  Td,
  Th,
  Thead,
  Tr,
  TrProps,
} from 'src/components/data-display/table';
import { Stack } from 'src/components/layout/stack';
import {
  Pagination,
  PaginationProps,
} from 'src/components/navigation/pagination';
import { SpinnerContainer } from 'src/components/spinner-container';
import { Hint } from 'src/components/text/hint';
import { Colors } from 'src/styles';
import styled from 'styled-components';

type ColActions = {
  showMeta: boolean;
  setShowMeta: (value: boolean) => void;
};

type MetaProps = {
  close: () => void;
};

export type Col<Item> = {
  key: string;
  head: JSX.Element;
  body: (item: Item, index: number, actions: ColActions) => JSX.Element;
  /**
   * Either specify a fixed width like `'5.5rem'` as a string or a relative width
   * like `1` as a number which would be treated just like `'1fr'` in a CSS grid.
   *
   * Let's say you have a table like this:
   * ```tsx
   * <Table
   *   data={[]}
   *   cols={[
   *     { width: 2 },
   *     {}, // defaults to width: 1
   *     { width: '5.5rem' },
   *   ]}
   * />
   * ```
   *
   * This means your last column has a width of 5.5rem (plus padding which will be added later)
   * and the other two columns get the remaining space will the first one will be 66.6% and the
   * second one 33.3%.
   */
  width?: string | number;
};

export type ColGroup<Item> = {
  key: string;
  group: string;
  cols: (Col<Item> | false)[];
};

type RowProps<Item extends {}> = {
  item: Item;
  trProps: TrProps;
};

type BodyProps = {
  tbodyProps: TbodyProps;
};

type SmartRowProps<Item extends {}> = {
  item: Item;
  meta?: (item: Item, index: number, props: MetaProps) => JSX.Element;
  filteredCols: Col<Item>[];
  rowIndex: number;
  colsCount: number;
  onClickRow?: (item: Item, index: number) => void;
  row?: (rowProps: RowProps<Item>) => ReactNode;
  light?: boolean;
};

function SmartRow<Item extends {}>({
  item,
  meta,
  filteredCols,
  rowIndex,
  colsCount,
  onClickRow,
  light,
  row,
}: SmartRowProps<Item>) {
  const [showMeta, setShowMeta] = useState(false);
  const isEven = Boolean(rowIndex % 2);

  const trProps: TrProps = {
    isEven,
    hideBorder: showMeta,
    light,
    onClick: onClickRow ? () => onClickRow(item, rowIndex) : undefined,
    children: filteredCols.map((col) => (
      <Fragment key={col.key}>
        {col.body(item, rowIndex, { showMeta, setShowMeta })}
      </Fragment>
    )),
  };

  return (
    <>
      {row ? row({ item, trProps }) : <Tr {...trProps} light={light} />}

      {showMeta && meta && (
        <tr>
          <td colSpan={colsCount} style={{ padding: 0 }}>
            <EmbeddedCard>
              {meta(item, rowIndex, { close: () => setShowMeta(false) })}
            </EmbeddedCard>
          </td>
        </tr>
      )}
    </>
  );
}

type SetId<Item extends { id?: string | number }> = Item['id'] extends
  | string
  | number
  ? { setId?: (item: Item, index: number) => string | number }
  : { setId: (item: Item, index: number) => string | number };

type TableProps<Item extends {}> = {
  top?: string;
  data: readonly Item[];
  meta?: (item: Item, index: number, props: MetaProps) => JSX.Element;
  cols: (ColGroup<Item> | Col<Item> | false | undefined)[];
  onClickHeader?: () => void;
  onClickRow?: (item: Item, index: number) => void;
  empty?: EmptyCardProps;
  'data-testid'?: string;
  pending?: boolean;
  paginated?: PaginationProps;
  hint?: ReactNode;
  row?: (rowProps: RowProps<Item>) => ReactNode;
  body?: (bodyProps: BodyProps) => ReactNode;
  mode?: 'regular' | 'light';
  staticHeader?: boolean;
} & SetId<Item>;

export function Table<Item extends {}>({
  setId,
  data,
  cols,
  onClickHeader,
  onClickRow,
  empty,
  meta,
  'data-testid': testId,
  top,
  pending = false,
  paginated,
  hint,
  body,
  row,
  mode = 'regular',
  staticHeader = false,
}: TableProps<Item>) {
  const truthyCols = cols.filter(Boolean) as (ColGroup<Item> | Col<Item>)[];

  const flattenedCols = flatten(
    truthyCols.map((item) => {
      if ('cols' in item) {
        return item.cols.filter(Boolean) as Col<Item>[];
      } else {
        return item;
      }
    })
  );
  const widths = flattenedCols.map(({ width = 1 }) => width);

  const hasGroups = Boolean(truthyCols.filter((item) => 'cols' in item).length);

  const tbodyProps = {
    'data-testid': testId ? `${testId}-body` : undefined,
    children: data.map((item, index) => {
      // guidance while doing development
      if (process.env.NODE_ENV !== 'production') {
        if (!('id' in item) && !setId) {
          throw new Error(
            'Your table item has no "id" field. Please use <Table setId={(item) => item.someId} /> to create an id on the fly.'
          );
        }
      }
      return (
        <SmartRow
          onClickRow={onClickRow}
          light={mode === 'light'}
          key={setId ? setId(item, index) : (item as any).id}
          colsCount={flattenedCols.length}
          item={item}
          meta={meta}
          filteredCols={flattenedCols}
          rowIndex={index}
          row={row}
        />
      );
    }),
  } as TbodyProps;

  return (
    <Stack gap={1}>
      <div data-testid={testId}>
        <SpinnerContainer pending={pending}>
          <SimpleTable
            widths={widths}
            top={top}
            light={mode === 'light'}
            sticky={!staticHeader}
          >
            <Thead onClick={onClickHeader}>
              {hasGroups && (
                <>
                  {/*
                    Our tables use `table-layout: fixed;` and according to MDN "column widths
                    are set by the widths of ... the first row of cells". In case of grouped column headers
                    we have an irregular amount of columns and therefor the column widths would be off.
                    That's why we have to use a row with empty cells, before we render the groups.
                    See https://developer.mozilla.org/en-US/docs/Web/CSS/table-layout#values
                  */}
                  <tr aria-hidden>
                    {flattenedCols.map((col) => (
                      <th key={col.key} style={{ padding: 0 }} />
                    ))}
                  </tr>
                  <Tr>
                    {truthyCols.map((col) =>
                      'cols' in col ? (
                        <EmptyColTh
                          key={col.key}
                          colSpan={col.cols.filter(Boolean).length}
                        >
                          {col.group}
                        </EmptyColTh>
                      ) : (
                        <ColTh key={col.key} />
                      )
                    )}
                  </Tr>
                </>
              )}

              <Tr>
                {flattenedCols.map((col) => (
                  <Fragment key={col.key}>{col.head}</Fragment>
                ))}
              </Tr>
            </Thead>

            {body ? body({ tbodyProps }) : <Tbody {...tbodyProps} />}
          </SimpleTable>

          {data.length === 0 && empty && (
            <EmptyCard
              {...empty}
              {...(testId ? { 'data-testid': `${testId}-empty-card` } : {})}
            />
          )}
        </SpinnerContainer>

        {paginated && <Pagination {...paginated} />}
      </div>

      {Boolean(hint) && <Hint mode="regular" children={hint} />}
    </Stack>
  );
}

type ExpandButtonProps = ButtonProps & {
  showMeta: boolean;
  setShowMeta: (showMeta: boolean) => void;
  showLabel: string;
  hideLabel: string;
};

export const ExpandButton: FC<ExpandButtonProps> = (props) => {
  const { showMeta, setShowMeta, showLabel, hideLabel, ...rest } = props;

  return (
    <Button mode="icon" onClick={() => setShowMeta(!showMeta)} {...rest}>
      {showMeta ? (
        <FontAwesomeIcon icon={faChevronCircleDown} aria-label={hideLabel} />
      ) : (
        <FontAwesomeIcon icon={faChevronCircleRight} aria-label={showLabel} />
      )}
    </Button>
  );
};

type SortableBodyPropsDual<Params extends { [key: string]: unknown }> = {
  data: [string | ReactNode, string | ReactNode];
  columns: [string, string];
  params: { value: Params; set: (value: Partial<Params>) => void };
};

export const SortableBody = (
  props: SortableBodyPropsDual<{ [key: string]: unknown }>
) => {
  let [firstLine, secondLine]: [string | ReactNode, string | ReactNode] =
    props.data;

  let sortColumn = props.params.value.sortBy as string;

  if (sortColumn === props.columns[1]) {
    [firstLine, secondLine] = [secondLine, firstLine];
  }

  return (
    <Td>
      {firstLine}
      <br />
      <small>{secondLine}</small>
    </Td>
  );
};

const EmptyColTh = styled(Th)`
  border-left: none;
  border-bottom: 0.1rem solid ${Colors.background};
  padding: 0.4rem 0.8rem;
`;

const ColTh = styled.th`
  background-color: ${Colors.brandBorder};
  border-left: none;
  border-right: 0.1rem solid ${Colors.background};
  padding: 0.4rem 0.8rem;
  &:first-child {
    border-left: 0.1rem solid ${Colors.background};
  }
`;

export const EmbeddedCard = styled(Card)`
  box-shadow: inset 0.1rem 0.4rem 1rem -0.6rem rgba(0, 0, 0, 0.5);
  border-bottom: 0.1rem solid ${Colors.brandBorder};
  border-left: 0.1rem solid ${Colors.background};
  border-right: 0.1rem solid ${Colors.background};
  border-top: none;
`;
