import {
  descSortDirectionSchema,
  getPaginationSchema,
} from 'src/components/form/zod-schemas';
import { preprocessAsArray } from 'src/components/form/zod-utilities';
import { shipperSuggestionSchema } from 'src/pages/shippers/credit-limits/shippers-suggestions-input';
import { z } from 'zod';

export function getCreditLimitsPageParamsSchema() {
  return z
    .object({
      creditLimitIdentifier: z.string().nullable().default(null),
      frameworkLabels: z.preprocess(
        preprocessAsArray,
        z.array(z.string()).default([])
      ),
      shippers: z.array(shipperSuggestionSchema).default([]),
      intervalStart: z.string().nullable().default(null),
      intervalEnd: z.string().nullable().default(null),
      createdAtFrom: z.string().nullable().default(null),
      createdAtTo: z.string().nullable().default(null),
      changedAtFrom: z.string().nullable().default(null),
      changedAtTo: z.string().nullable().default(null),
      sortBy: z
        .enum([
          'LIMIT_IDENTIFIER',
          'FRAMEWORK_LABEL',
          'SHIPPER_NAME',
          'CREDIT_AMOUNT',
          'USED_AMOUNT',
        ])
        .default('SHIPPER_NAME'),
      sortDirection: descSortDirectionSchema,
    })
    .merge(getPaginationSchema());
}
