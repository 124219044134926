// this wrapper is needed to avoid jest error
// see https://github.com/jsdom/jsdom/issues/3492

/**
 * It is very rare that you actually need to use this function, except
 * if you trigger a file download with it.
 *
 * This function might be used in very rare cases where some global
 * state is not fully controlled by React. (E.g. if the authentication state
 * needs to be updated and this needs to be synced with Angular.)
 */
export function setLocationHref(href: string) {
  // eslint-disable-next-line rulesdir/location-usage
  location.href = href;
}

/**
 * It is very rare that you actually need to use this function.
 * If you just want to refresh data on your page, please use the
 * `refresh` method on your requests.
 *
 * This function might be used in very rare cases where some global
 * state is not fully controlled by React. (E.g. if the authentication state
 * needs to be updated and this needs to be synced with Angular.)
 */
export function locationReload() {
  // eslint-disable-next-line rulesdir/location-usage
  location.reload();
}

/**
 * It is very rare that you actually need to use this function.
 *
 * This function might be used in very rare cases where some global
 * state is not fully controlled by React. (E.g. if the authentication state
 * needs to be updated and this needs to be synced with Angular.)
 *
 * Another case where used it is an opt-in/opt-out mechanism where you
 * switch between Angular and React.
 */
export function locationReplace(url: string) {
  // eslint-disable-next-line rulesdir/location-usage
  location.replace(url);
}
