import { FC, lazy, useEffect } from 'react';
import { InformationSheetsParams } from 'src/apis/aggregate-eu/types';
import { AggregateEuRootRequest } from 'src/apis/aggregate-eu/use-aggregate-eu-root';
import { useGetLocations } from 'src/apis/aggregate-eu/use-get-locations';
import { useInformationSheets } from 'src/apis/aggregate-eu/use-info-sheets';
import { PageSpinner } from 'src/components/spinner-container';
import { Successful } from 'src/hooks/use-axios';
import { usePageParams } from 'src/pages/aggregate-eu/demands/my-information-sheets/schema';
import { useMemoOne } from 'use-memo-one';

const Page = lazy(() => import('./page'));

type Props = {
  aggregateEuRootRequest: Successful<AggregateEuRootRequest>;
};

export const AggregateEuMyInformationSheets: FC<Props> = ({
  aggregateEuRootRequest,
}) => {
  const informationSheetsRequest = useInformationSheets();
  const locations = useGetLocations({ aggregateEuRootRequest });
  const pageParams = usePageParams();

  const params: InformationSheetsParams = useMemoOne(() => {
    const {
      start,
      pageSize,
      processType,
      locationId,
      startOfMonthStart,
      startOfMonthEnd,
    } = pageParams.value;
    return {
      limit: pageSize,
      offset: start,
      processType,
      locationId,
      startOfMonthStart: startOfMonthStart ?? undefined,
      startOfMonthEnd: startOfMonthEnd ?? undefined,
    };
  }, [pageParams.value]);

  useEffect(() => {
    const rootData = aggregateEuRootRequest.response.data;
    const options = { rootData, params };

    informationSheetsRequest.execute(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aggregateEuRootRequest.response, params]);

  return !locations.response ? (
    <PageSpinner />
  ) : (
    <Page
      pageParams={pageParams}
      informationSheets={informationSheetsRequest}
      locations={locations.response.data}
    />
  );
};
