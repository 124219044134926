import { useEffect } from 'react';
import { Uuid } from 'src/apis/api-utilities';
import { PublicOperatorDetailsResponse } from 'src/apis/organisation-service/types';
import { OrganisationServicePublicRequest } from 'src/apis/organisation-service/use-organisation-service-public';
import { useAxios } from 'src/hooks/use-axios';

type HookOptions = {
  operatorUuid?: Uuid;
  organisationServicePublic: OrganisationServicePublicRequest;
};

/**
 * Every operator role is supported, but you cannot choose the details
 * of a specific role in case the organisation has multiple roles.
 * You'll get them in this order: TSO, SSO, LSO.
 */
export function usePublicOperatorDetails({
  operatorUuid,
  organisationServicePublic,
}: HookOptions) {
  const operator = useAxios(
    (
      axios,
      baseConfig,
      { url, operatorUuid }: { url: string; operatorUuid: string }
    ) =>
      axios.request<PublicOperatorDetailsResponse>({
        ...baseConfig,
        url: `${url}/${operatorUuid}`, // TODO: use _links
        method: 'GET',
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!organisationServicePublic.response || !operatorUuid) return;
    operator.execute({
      url: organisationServicePublic.response.data._links.getOperators.href,
      operatorUuid,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationServicePublic.response, operatorUuid]);

  return operator;
}

export type PublicOperatorDetailsRequest = ReturnType<
  typeof usePublicOperatorDetails
>;

export function useManualPublicOperatorDetails() {
  const operator = useAxios(
    (axios, baseConfig, url: string) =>
      axios.request<PublicOperatorDetailsResponse>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: false }
  );

  return operator;
}

export type ManualPublicOperatorDetailsRequest = ReturnType<
  typeof useManualPublicOperatorDetails
>;
