import escapedString from 'escape-string-regexp';
import { FC, useEffect, useMemo, useRef } from 'react';
import { InteractiveCard } from 'src/components/data-display/card';
import { Tag, TagProps } from 'src/components/data-display/tag';
import {
  SimpleCheckbox,
  SimpleCheckboxProps,
} from 'src/components/form/checkbox';
import { FormOption } from 'src/components/form/form-option';
import {
  Suggestions,
  SuggestionsInput,
} from 'src/components/form/suggestions-input';
import { formOptionSchema } from 'src/components/form/zod-schemas';
import { Tooltip } from 'src/components/overlay/tooltip';
import { useAxios } from 'src/hooks/use-axios';
import { useSearch } from 'src/hooks/use-search';
import { AssignedShippersPageResponse } from 'src/pages/shippers/details/information/types';
import { z } from 'zod';

type Props = {
  name?: string;
  label: string;
  placeholder: string;
};

type ShipperMeta = { shipperRefId: number };
export type ShipperSuggestion = FormOption<string> & ShipperMeta;

const shipperMetaSchema = z.object({ shipperRefId: z.coerce.number() });
export const shipperSuggestionSchema = formOptionSchema.and(shipperMetaSchema);

export const ShippersSuggestionsInput: FC<Props> = ({
  label,
  placeholder,
  name = 'shipperEic',
}) => {
  const [searchValue, setSearchValue, searchQuery] = useSearch('', {
    minLength: 1,
  });

  const searchRequest = useAxios(
    (axios, baseConfig) =>
      axios.request<AssignedShippersPageResponse>({
        ...baseConfig,
        params: {
          sortColumn: 'shipperName',
          start: 0,
          pageSize: 100000,
          sortAscending: true,
        },
        url: `${PRISMA_CONFIG.monolithApiUrl}/tso/assigned-shippers/page`,
      }),
    { neededOnPageLoad: false }
  );

  // on the very first search input we try to fetch the initial list
  useEffect(() => {
    if (!searchQuery || searchRequest.response) return;
    searchRequest.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, searchRequest.response]);

  const searchResult = useMemo(() => {
    if (!searchQuery || !searchRequest.response) return;

    const escapedSearchQuery = new RegExp(
      escapedString(searchQuery.toLowerCase())
    );

    const suggestions: Suggestions<ShipperMeta> = {
      options: searchRequest.response.data.data
        .filter(
          (shipper) =>
            shipper.shipperName.toLowerCase().match(escapedSearchQuery) ||
            shipper.eic.toLowerCase().match(escapedSearchQuery)
        )
        .map((shipper) => ({
          value: shipper.eic,
          label: shipper.shipperName,
          shipperRefId: shipper.shipperRefId,
        })),
      truncated: false,
    };

    return suggestions;
  }, [searchQuery, searchRequest.response]);

  return (
    <SuggestionsInput<ShipperMeta>
      initialActive={false}
      label={label}
      name={name}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      pending={searchRequest.pending}
      searchResult={searchResult}
      lastSearchQuery={searchQuery}
      stacked
      SuggestionItem={SuggestionItem}
      SelectedItem={SelectedItem}
      placeholder={placeholder}
      mode="objects"
    />
  );
};

const SuggestionItem: FC<{
  checkboxProps: SimpleCheckboxProps;
  option: ShipperSuggestion;
}> = ({ checkboxProps, option }) => {
  const checkboxRef = useRef<HTMLInputElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);
  return (
    <InteractiveCard
      onClick={(event) => {
        const clickedCheckbox =
          event.target instanceof Node &&
          labelRef.current?.contains(event.target);

        if (clickedCheckbox) return;
        checkboxRef.current?.click();
      }}
    >
      <strong>
        <SimpleCheckbox
          ref={checkboxRef}
          labelRef={labelRef}
          {...checkboxProps}
        />
      </strong>

      <p>
        <small>{option.value}</small>
      </p>
    </InteractiveCard>
  );
};

export const SelectedItem: FC<{
  tagProps: TagProps;
  option: ShipperSuggestion;
}> = ({ tagProps, option }) => {
  return (
    <Tooltip
      content={
        <p>
          <small>
            <strong>EIC:</strong> {option.value}
          </small>
        </p>
      }
    >
      {(targetProps) => (
        <span {...targetProps}>
          <Tag {...tagProps} testid={`selected-shipper-${option.value}`} />
        </span>
      )}
    </Tooltip>
  );
};
