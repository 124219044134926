import {
  InternalBookingsSortBy,
  MaintenancePlansSortBy,
} from 'src/apis/capacity-management/types';
import {
  descSortDirectionSchema,
  getPaginationSchema,
} from 'src/components/form/zod-schemas';
import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import { UnionToTuple } from 'src/utils/labels-to-values';
import { z } from 'zod';

const internalBookingsSchema = z
  .object({
    sortBy: z
      .enum(['validFrom'] satisfies UnionToTuple<InternalBookingsSortBy>)
      .default('validFrom'),
    sortDirection: descSortDirectionSchema,
  })
  .merge(getPaginationSchema());

const maintenancePlansSchema = z
  .object({
    sortBy: z
      .enum([
        'validFrom',
        'validTo',
        'planned',
      ] satisfies UnionToTuple<MaintenancePlansSortBy>)
      .default('validFrom'),
    sortDirection: descSortDirectionSchema,
  })
  .merge(getPaginationSchema());

export function usePageParams() {
  const internalBookings = usePageParamsFromSchema(internalBookingsSchema, {
    groupPrefix: 'internalBookings',
  });

  const maintenancePlans = usePageParamsFromSchema(maintenancePlansSchema, {
    groupPrefix: 'maintenancePlans',
  });

  return {
    internalBookings,
    maintenancePlans,
  };
}

export type PageParams = ReturnType<typeof usePageParams>;
