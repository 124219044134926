import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useState } from 'react';
import { ServicePackage, ServicePackageId } from 'src/apis/monolith/types';
import { Button } from 'src/components/buttons-and-actions/button';
import { FormatNumber } from 'src/components/data-display/format-number';
import { Divider } from 'src/components/dividers';
import { Stack } from 'src/components/layout/stack';

import {
  availableFeatures,
  packageAddon,
} from 'src/pages/settings/premium-services/prisma-api-available-features';
import { SelectServicePackageStepData } from 'src/pages/settings/premium-services/prisma-api-wizard-model';
import {
  WizardStepMetadata,
  WizardSummary,
} from 'src/pages/settings/premium-services/prisma-api-wizard-summary';
import styled from 'styled-components';

const PrismaApiWizardSelectPackageStep: FC<{
  steps: WizardStepMetadata[];
  setActiveStep: (step: number) => void;
  setData: (data: SelectServicePackageStepData) => void;
  servicePackages: ServicePackage[];
}> = ({ steps, setActiveStep, setData, servicePackages }) => {
  const [selectedAddons, setSelectedAddons] = useState<
    Partial<Record<ServicePackageId, boolean>>
  >({});

  const isAddonSelected = (addon: ServicePackageId) => selectedAddons[addon];

  const setAddonSelected = (addon: ServicePackageId, selected: boolean) =>
    setSelectedAddons((current) => ({ ...current, [addon]: selected }));

  return (
    <>
      <WizardSummary
        steps={steps}
        activeStep={1}
        navigable
        setActiveStep={(step) => setActiveStep(step)}
      />
      <Stack gap={2}>
        <p>
          Depending on the use case you want to automate you may choose from
          three different service packages.
        </p>
        <Divider />

        <Stack gap={3}>
          {servicePackages.map((servicePackage) => {
            const addon = packageAddon[servicePackage.identifier];

            if (addon?.hidden) return null;

            const addonPackage =
              addon?.addonPackage &&
              servicePackages.find((p) => p.identifier === addon.addonPackage);

            return (
              <Stack
                key={servicePackage.identifier}
                gap={1}
                data-testid={`package-${servicePackage.identifier}`}
              >
                <details>
                  <Summary data-testid={`summary-${servicePackage.identifier}`}>
                    {addon?.label ?? servicePackage.label} (
                    <FormatNumber value={servicePackage.price.value} />{' '}
                    {servicePackage.unit})
                  </Summary>
                  {availableFeatures
                    .filter(
                      (availableFeature) =>
                        !availableFeature.hidden?.includes(
                          servicePackage.identifier
                        )
                    )
                    .map((availableFeature, index) => {
                      const available = availableFeature.enabled.some(
                        (feature) =>
                          servicePackage.identifier === feature ||
                          (addonPackage &&
                            isAddonSelected(addonPackage.identifier) &&
                            addonPackage.identifier === feature)
                      );
                      return (
                        <p
                          key={index}
                          data-testid={`${servicePackage.identifier}-${index}-${available}`}
                        >
                          <FontAwesomeIcon
                            icon={available ? faCheckCircle : faTimesCircle}
                          />
                          &nbsp;
                          <span>{availableFeature.label}</span>
                        </p>
                      );
                    })}
                </details>
                <Stack flow="column" templateColumns="1fr auto">
                  {addon && addonPackage ? (
                    <FcfsAddonLabel
                      data-testid={`fcfs-addon-${addonPackage.identifier}`}
                    >
                      <input
                        type="checkbox"
                        checked={!!isAddonSelected(addonPackage.identifier)}
                        onChange={(e) =>
                          setAddonSelected(
                            addonPackage.identifier,
                            e.target.checked
                          )
                        }
                      />
                      FCFS Booking Add-on (+
                      <FormatNumber
                        value={
                          addonPackage.price.value - servicePackage.price.value
                        }
                      />{' '}
                      {addonPackage.unit})
                    </FcfsAddonLabel>
                  ) : (
                    <span />
                  )}
                  <Button
                    mode="primary"
                    data-testid={'choose-' + servicePackage.identifier}
                    onClick={() => {
                      setData({
                        servicePackage:
                          addonPackage &&
                          isAddonSelected(addonPackage.identifier)
                            ? addonPackage
                            : servicePackage,
                      });
                      setActiveStep(2);
                    }}
                  >
                    Choose
                  </Button>
                </Stack>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    </>
  );
};

export default PrismaApiWizardSelectPackageStep;

const Summary = styled.summary`
  font-size: 1.8rem;
  font-weight: 900;
`;

const FcfsAddonLabel = styled.label`
  input {
    margin-right: 0.5rem;
    accent-color: rgb(0, 55, 139);
  }
`;
