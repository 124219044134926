import { directionValues } from 'src/components/data-display/direction';
import {
  descSortDirectionSchema,
  getPaginationSchema,
} from 'src/components/form/zod-schemas';
import {
  preprocessAsArray,
  preprocessAsBoolean,
} from 'src/components/form/zod-utilities';
import { mixedProductTypeValues } from 'src/pages/reporting/auctions/components/period-type';
import {
  transactionStatusValues,
  transactionTypeValues,
} from 'src/pages/reporting/my-transactions/labels';
import { networkPointSuggestionSchema } from 'src/pages/reporting/my-transactions/network-point-suggestions-input';
import { shipperSuggestionSchema } from 'src/pages/reporting/shipper-transactions/shippers-suggestions-input';
import { z } from 'zod';

export const shipperTransactionsPageParamsSchema = z
  .object({
    sortBy: z.enum(['CREATED_AT']).default('CREATED_AT'),
    sortDirection: descSortDirectionSchema,
    createdAtStart: z.string().nullable().default(null),
    createdAtEnd: z.string().nullable().default(null),
    shipperId: z.preprocess(
      preprocessAsArray,
      z.array(shipperSuggestionSchema).default([])
    ),
    networkPointId: z.preprocess(
      preprocessAsArray,
      z.array(networkPointSuggestionSchema).default([])
    ),
    direction: z.preprocess(
      preprocessAsArray,
      z.array(z.enum(directionValues)).default([])
    ),
    marketAreaId: z.preprocess(
      preprocessAsArray,
      z.array(z.string()).default([])
    ),
    capacityCategoryId: z.preprocess(
      preprocessAsArray,
      z.array(z.string()).default([])
    ),
    productType: z.preprocess(
      preprocessAsArray,
      z.array(z.enum(mixedProductTypeValues)).default([])
    ),
    runtimeIntersectionFilterStart: z.string().nullable().default(null),
    runtimeIntersectionFilterEnd: z.string().nullable().default(null),
    transactionType: z.preprocess(
      preprocessAsArray,
      z.array(z.enum(transactionTypeValues)).default([])
    ),
    transactionTypeSpecificId: z.string().nullable().default(null),
    status: z.preprocess(
      preprocessAsArray,
      z.array(z.enum(transactionStatusValues)).default([])
    ),
    regulatedTariffMin: z.coerce.number().nullable().default(null),
    regulatedTariffMax: z.coerce.number().nullable().default(null),
    onlyWithSurcharge: z.preprocess(
      preprocessAsBoolean,
      z.boolean().default(false)
    ),
    surchargeMin: z.coerce.number().nullable().default(null),
    surchargeMax: z.coerce.number().nullable().default(null),
    amountMin: z.coerce
      .number()
      .int(`{label} cannot contain decimals.`)
      .nullable()
      .default(null),
    amountMax: z.coerce
      .number()
      .int(`{label} cannot contain decimals.`)
      .nullable()
      .default(null),
  })
  .merge(getPaginationSchema());
