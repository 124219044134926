import {
  NetworkPointSimple,
  ConnectionPointType,
  BundlingSituation,
  SalesMethod,
  ProductPeriodType,
  FlowUnit,
  PriceUnit,
} from 'src/apis/monolith/types';
import { DirectionType } from 'src/components/data-display/direction';
import { AuthenticatedMonolithUserContextValue } from 'src/hooks/use-monolith-user';
import { physicalUnitLabels } from 'src/pages/transport/secondary-tradings/trade/network-point-card';
import { labelsToOptions } from 'src/utils/labels-to-options';
import { labelsToValues } from 'src/utils/labels-to-values';

export const userIsOwnerOfNetworkPoint = (
  auth: AuthenticatedMonolithUserContextValue,
  networkPoint: NetworkPointSimple
) =>
  auth?.isTso &&
  (auth.referenceId === networkPoint.tso0Id ||
    (networkPoint.bundled && auth.referenceId === networkPoint.tso1Id));

export const connectionPointTypeLabels: Record<ConnectionPointType, string> = {
  MARKET_TRANSITION_POINT: 'Market Area Interconnection Point',
  BORDER_TRANSITION_POINT: 'Cross-Border Interconnection Point',
  RESERVOIR: 'Storage Interconnection Point',
  GRID_ACCESS_POINT: 'End Consumer Exit Point',
  PRODUCTION: 'Production Entry Point',
  BIOGAS_ENTRY: 'Biogas Entry Point',
  OTHER_NETWORK_POINT: 'Other Network Point',
};

export const bundlingLabels: Record<BundlingSituation, string> = {
  NOT_BUNDLED: 'unbundled',
  PART_OF_BUNDLE: 'part of bundle',
  ONE_TO_ONE_BUNDLE: '1:1',
  ONE_TO_MANY_BUNDLE: '1-to-n',
};

export const salesMethodLabels: Record<SalesMethod, string> = {
  AUCTION: 'Auction',
  FCFS: 'FCFS',
  SECONDARY: 'Secondary',
};

export const salesMethodOptions = labelsToOptions(salesMethodLabels);

export const salesMethodValues = labelsToValues(salesMethodLabels);

export const productPeriodTypeLabels: Record<ProductPeriodType, string> = {
  INTRADAY: 'Within-day',
  DAY: 'Day',
  MONTH: 'Month',
  QUARTER: 'Quarter',
  YEAR: 'Year',
};

export const productPeriodTypeOptions = labelsToOptions(
  productPeriodTypeLabels
);

export const productPeriodTypeValues = labelsToValues(productPeriodTypeLabels);

export const flowUnitLabels: Record<FlowUnit, string> = {
  K_WH_H: 'kWh/h',
  K_WH_D: 'kWh/d',
  M3_H: 'Cm³/h',
};

export const flowUnitOptions = labelsToOptions(flowUnitLabels);

export const flowUnitValues = labelsToValues(flowUnitLabels);

export const directionLabels: Record<DirectionType, string> = {
  ENTRY: 'Entry',
  EXIT: 'Exit',
  BUNDLE: 'Bundle',
};

export const directionOptions = labelsToOptions(directionLabels);

// Currently used only for CF with EUR as a currency
export const transformToDays = (unit: PriceUnit) =>
  unit.currencySubunit
    ? `cent/${physicalUnitLabels[unit.physicalUnit!]}/d`
    : `EUR/${physicalUnitLabels[unit.physicalUnit!]}/d`;
