import { TsoInvoicesSortBy } from 'src/apis/capacity-management/types';
import {
  descSortDirectionSchema,
  formOptionSchema,
  getPaginationSchema,
} from 'src/components/form/zod-schemas';
import {
  preprocessAsArray,
  preprocessAsBoolean,
} from 'src/components/form/zod-utilities';
import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import {
  invoicesStatusValues,
  invoicesTypeValues,
  monthlyStatementTypeValues,
} from 'src/pages/shippers/invoices/schema';

import { labelsToValues } from 'src/utils/labels-to-values';
import { z } from 'zod';

const sortByLabels: Record<TsoInvoicesSortBy, string> = {
  CREATION_DATE: 'Creation Date',
  INVOICE_NUMBER: 'Invoice Number',
  DUE_DATE: 'Due Date',
  TSO_NAME: 'TSO Name',
  AMOUNT: 'Amount',
  STATUS: 'Status',
};

const sortByValue = labelsToValues(sortByLabels);

const invoicesPageParamsSchema = z
  .object({
    sortBy: z.enum(sortByValue).default('CREATION_DATE'),
    sortDirection: descSortDirectionSchema,
    creationDateStart: z.string().nullable().default(null),
    creationDateEnd: z.string().nullable().default(null),
    invoiceNumber: z.string().nullable().default(null),
    dueDateStart: z.string().nullable().default(null),
    dueDateEnd: z.string().nullable().default(null),
    tsoId: z.preprocess(
      preprocessAsArray,
      z
        .array(formOptionSchema.merge(z.object({ shortName: z.string() })))
        .default([])
    ),
    contractId: z.preprocess(
      preprocessAsArray,
      z
        .array(formOptionSchema.merge(z.object({ tsoShortName: z.string() })))
        .default([])
    ),
    status: z.preprocess(
      preprocessAsArray,
      z.array(z.enum(invoicesStatusValues)).default([])
    ),
    invoiceType: z.preprocess(
      preprocessAsArray,
      z.array(z.enum(invoicesTypeValues)).default([])
    ),
    monthlyStatementType: z.preprocess(
      preprocessAsArray,
      z.array(z.enum(monthlyStatementTypeValues)).default([])
    ),
    onlyOverdue: z.preprocess(preprocessAsBoolean, z.boolean().default(false)),
  })
  .merge(getPaginationSchema());

export function usePageParams() {
  return usePageParamsFromSchema(invoicesPageParamsSchema, {
    filters: {
      creationDateStart: { label: 'Created on' },
      creationDateEnd: { label: 'Created on' },
      invoiceNumber: { label: 'Invoice Number' },
      dueDateStart: { label: 'Due Date' },
      dueDateEnd: { label: 'Due Date' },
      tsoId: { label: 'TSO' },
      contractId: { label: 'Contract' },
      status: { label: 'Invoice Status' },
      invoiceType: { label: 'Invoice Type' },
      monthlyStatementType: { label: 'Monthly Statement Type' },
      onlyOverdue: { label: 'Overdue Status' },
    },
  });
}

export type PageParams = ReturnType<typeof usePageParams>;
