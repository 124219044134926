import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useCognito } from 'src/hooks/use-cognito';
import { useCognitoLastAuthUser } from 'src/hooks/use-cognito-last-auth-user';
import { useCognitoUser } from 'src/hooks/use-cognito-user';
import { useSafeLocalStorage } from 'src/hooks/use-storage';
import { assertContext } from 'src/utils/assert-context';
import { z } from 'zod';

export const authStorageKey = 'prisma.auth';

const accountSchema = z.object({
  cognitoUserName: z.string(),
  email: z.string(),
  /**
   * The organisation name is optional, because we fetch it from the
   * organisation-service when the user logs in, but we don't want to make the login
   * fail, if the organisation-service is not reachable.
   */
  organisationName: z.string().nullable(),
});

export type AuthAccount = z.output<typeof accountSchema>;

const authDataSchema = z.object({
  accounts: z.array(accountSchema).default([]),
  acceptedTerms: z.boolean().default(false),
});

export type AuthData = z.output<typeof authDataSchema>;

const defaultAuthData: AuthData = { accounts: [], acceptedTerms: false };

function useAccountsManager() {
  const cognito = useCognito();
  const cognitoUser = useCognitoUser();
  const safeLocalStorage = useSafeLocalStorage();
  const cognitoLastAuthUser = useCognitoLastAuthUser();

  const [authData, setAuthData] = useState<AuthData>(() => {
    try {
      const authStorage = safeLocalStorage.getItem(authStorageKey);
      return authDataSchema.parse(JSON.parse(authStorage ?? '{}'));
    } catch (err) {
      return defaultAuthData;
    }
  });

  useEffect(() => {
    safeLocalStorage.setItem(authStorageKey, JSON.stringify(authData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authData]);

  const value = useMemo(
    () => {
      const current =
        authData.accounts.find(
          (account) => account.cognitoUserName === cognitoUser?.cognitoUserName
        ) ?? null;
      return {
        current,

        items: [...authData.accounts].sort((a, b) => {
          if (a.organisationName === b.organisationName) {
            return a.email.localeCompare(b.email);
          }
          return (a.organisationName ?? '').localeCompare(
            b.organisationName ?? ''
          );
        }),

        /**
         * This adds the account data if it wasn't present before and for existing accounts it checks,
         * if the company name needs to be updated. (It might have changed since the last time.)
         */
        save: (accountData: AuthAccount) => {
          const existingAccountIndex = authData.accounts.findIndex(
            (account) => account.cognitoUserName === accountData.cognitoUserName
          );
          if (existingAccountIndex !== -1) {
            const existingAccount = authData.accounts[existingAccountIndex];
            if (
              accountData.organisationName &&
              existingAccount.organisationName !== accountData.organisationName
            ) {
              // existing account needs to be updated
              setAuthData((authData) => ({
                ...authData,
                accounts: [
                  ...authData.accounts.slice(0, existingAccountIndex),
                  {
                    ...existingAccount,
                    organisationName: accountData.organisationName,
                  },
                  ...authData.accounts.slice(existingAccountIndex + 1),
                ],
              }));
            }
          } else {
            // new account needs to be added
            setAuthData((authData) => ({
              ...authData,
              accounts: [...authData.accounts, accountData],
            }));
          }
        },

        /**
         * This removes an account from our "Switch Account" feature and logs out that account.
         */
        forgetAccount: async (targetAccount: AuthAccount) => {
          const currentAccount = current;

          setAuthData((authData) => ({
            ...authData,
            accounts: authData.accounts.filter(
              (account) =>
                account.cognitoUserName !== targetAccount.cognitoUserName
            ),
          }));
          cognitoLastAuthUser.set(targetAccount);
          await cognito.logoutFromCurrentlyUnusedAccount();
          if (currentAccount) cognitoLastAuthUser.set(currentAccount);
        },

        acceptedTerms: authData.acceptedTerms,

        acceptTerms: () => {
          setAuthData((authData) => ({
            ...authData,
            acceptedTerms: true,
          }));
        },

        switchAccount: (targetAccount: AuthAccount) => {
          const initiator = current;
          cognitoLastAuthUser.set(targetAccount);
          // you can only fetch user attributes, when the tokens are still valid
          return cognito.fetchUserAttributes().catch((error) => {
            if (initiator) cognitoLastAuthUser.set(initiator);
            throw error;
          });
        },

        /**
         * Deletes all locally stored accounts and settings related to the "Switch Account" feature.
         */
        reset: () => setAuthData(defaultAuthData),
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cognitoUser, authData]
  );

  return value;
}

export type AccountsContextType = ReturnType<typeof useAccountsManager>;

// pass undefined as any, because we run assertContext at runtime
const AccountsContext = createContext<AccountsContextType>(undefined as any);

export const AccountsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const accountsManager = useAccountsManager();
  return (
    <AccountsContext.Provider value={accountsManager}>
      {children}
    </AccountsContext.Provider>
  );
};

export const useAccounts = () => {
  const context = useContext(AccountsContext);
  assertContext(context, 'Accounts');
  return context;
};
