import { ServicePackageId } from 'src/apis/monolith/types';

export type AvailableFeature = {
  label: string;
  enabled: ServicePackageId[];
  hidden?: ServicePackageId[];
};

export const availableFeatures: AvailableFeature[] = [
  {
    label: 'Successful bid confirmations',
    enabled: ['RECONCILIATION', 'ANALYST', 'TRADER'],
  },
  {
    label: 'FCFS confirmations',
    enabled: ['RECONCILIATION', 'ANALYST', 'TRADER'],
  },
  {
    label: 'Secondary trade confirmations',
    enabled: ['RECONCILIATION', 'ANALYST', 'TRADER'],
  },

  // Regular FCFS
  {
    label: 'Regular FCFS: Check available capacity',
    enabled: ['RECONCILIATION_PLUS_FCFS', 'ANALYST_PLUS_FCFS', 'TRADER'],
  },
  {
    label: 'Regular FCFS: Book capacity',
    enabled: ['RECONCILIATION_PLUS_FCFS', 'ANALYST_PLUS_FCFS', 'TRADER'],
  },
  {
    label: 'Regular FCFS: Check status of your booking',
    enabled: ['RECONCILIATION_PLUS_FCFS', 'ANALYST_PLUS_FCFS', 'TRADER'],
  },

  // Direct FCFS
  {
    label: 'Direct FCFS: Check available capacity',
    enabled: ['RECONCILIATION_PLUS_FCFS', 'ANALYST_PLUS_FCFS', 'TRADER'],
  },
  {
    label: 'Direct FCFS: Request offer',
    enabled: ['RECONCILIATION_PLUS_FCFS', 'ANALYST_PLUS_FCFS', 'TRADER'],
  },
  {
    label: 'Direct FCFS: Book capacity',
    enabled: ['RECONCILIATION_PLUS_FCFS', 'ANALYST_PLUS_FCFS', 'TRADER'],
  },

  {
    label: 'TSO master data',
    enabled: ['RECONCILIATION', 'ANALYST', 'TRADER'],
  },
  {
    label: 'Network point data',
    enabled: ['RECONCILIATION', 'ANALYST', 'TRADER'],
  },
  {
    label: 'Past auction data',
    enabled: ['ANALYST', 'TRADER'],
  },
  {
    label: 'Live auction data',
    enabled: ['ANALYST', 'TRADER'],
  },
  {
    label: 'Portfolio code data',
    enabled: ['TRADER'],
  },
  {
    label: 'Balancing group data',
    enabled: ['TRADER'],
  },
  {
    label: 'Bid submission',
    enabled: ['TRADER'],
  },
  {
    label: 'Access to test environment',
    enabled: ['RECONCILIATION', 'ANALYST', 'TRADER'],
  },
];

export const packageAddon: Partial<
  Record<
    ServicePackageId,
    {
      addonPackage?: ServicePackageId;
      hidden?: true;
      label?: string;
    }
  >
> = {
  ANALYST: {
    addonPackage: 'ANALYST_PLUS_FCFS',
  },
  ANALYST_PLUS_FCFS: {
    hidden: true,
  },
  RECONCILIATION: {
    addonPackage: 'RECONCILIATION_PLUS_FCFS',
  },
  RECONCILIATION_PLUS_FCFS: {
    hidden: true,
  },
  TRADER: {
    label: 'Trader+',
  },
};
