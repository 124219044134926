import { useEffect } from 'react';
import { ShipperPremiumServices } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

export function useShipperPremiumServices(referenceId: number) {
  const api = useAxios(
    (axios, baseConfig, referenceId: number) =>
      axios
        .request<ShipperPremiumServices | ''>({
          ...baseConfig,
          url: `${PRISMA_CONFIG.monolithApiUrl}/shippers/${referenceId}/shipper-premium-services-subscription`,
          method: 'GET',
          timeout: 5000,
        })
        .then((response) => ({
          ...response,
          data: response.data === '' ? null : response.data,
        })),
    {
      neededOnPageLoad: true,
      // cacheKey: `ShipperPremiumServices-${referenceId}`,
    }
  );

  useEffect(() => {
    api.execute(referenceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referenceId]);

  return api;
}

export type ShipperPremiumServicesRequest = ReturnType<
  typeof useShipperPremiumServices
>;
