import { FC, ReactNode } from 'react';
import { RadioInput } from 'src/components/form/radios';
import { Stack } from 'src/components/layout/stack';
import { Tooltip } from 'src/components/overlay/tooltip';

const ReadOnlyOption: FC<{
  selected: boolean;
  children: ReactNode;
  onClick?: () => void;
  disabled: boolean;
  tooltip?: string;
  testId?: string;
}> = ({ selected, onClick, disabled, tooltip, testId, children }) => {
  return (
    <Stack
      onClick={() => {
        if (!selected && onClick) {
          onClick();
        }
      }}
      gap={1}
      alignItems="baseline"
      templateColumns="15px 5fr"
    >
      <Tooltip content={tooltip}>
        {(targetProps) => (
          <RadioInput
            showError={false}
            readOnly
            disabled={disabled}
            checked={selected}
            {...targetProps}
            data-testid={testId && `option-${testId}`}
          />
        )}
      </Tooltip>
      <Stack gap={0}>{children}</Stack>
    </Stack>
  );
};

export default ReadOnlyOption;
