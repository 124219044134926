import {
  optionalDefault,
  preprocessAsArray,
  preprocessAsBoolean,
} from 'src/components/form/zod-utilities';
import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import { z } from 'zod';

const myDemandsSchema = z.object({
  offset: z.coerce.number().default(0),
  limit: z.coerce.number().default(500),
  processType: z.preprocess(
    optionalDefault(['NBP_PROCUREMENT', 'LNG_PROCUREMENT']),
    z.preprocess(
      preprocessAsArray,
      z.array(z.enum(['NBP_PROCUREMENT', 'LNG_PROCUREMENT']))
    )
  ),
  currentProcessOnly: z.preprocess(
    preprocessAsBoolean,
    z.boolean().default(true)
  ),
});

export function usePageParams() {
  return usePageParamsFromSchema(myDemandsSchema, {
    filters: {
      processType: { label: 'Process Type' },
    },
  });
}

export type PageParams = ReturnType<typeof usePageParams>;
