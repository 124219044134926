import {
  ascSortDirectionSchema,
  getPaginationSchema,
} from 'src/components/form/zod-schemas';
import {
  preprocessAsArray,
  preprocessAsBoolean,
} from 'src/components/form/zod-utilities';
import { periodTypeValues } from 'src/pages/reporting/auctions/components/period-type';
import { z } from 'zod';

export function getTransportAuctionsPageParamsForCalendarsSchema() {
  return z
    .object({
      calendarPeriodType: z.enum(periodTypeValues).default('WITHINDAY'),
      publicationDate: z.string().nullable().default(null),
      publicationDateEnd: z.string().nullable().default(null),
      auctionStartDate: z.string().nullable().default(null),
      auctionStartDateEnd: z.string().nullable().default(null),
      capacityCategory: z.preprocess(
        preprocessAsArray,
        z.array(z.enum(['Firm Types', 'Interruptible Types'])).default([])
      ),
      productRuntimeStart: z.string().nullable().default(null),
      productRuntimeEnd: z.string().nullable().default(null),
      sortAscending: z.preprocess(
        preprocessAsBoolean,
        z.boolean().default(true)
      ),
      sortColumn: z.string().default('publishDate'),
    })
    .merge(getPaginationSchema({ pageSize: 5 }));
}

export function getTransportAuctionsPageParamsForComfortBidsSchema() {
  return getPaginationSchema({ pageSize: 5 });
}

export function getTransportAuctionsPageParamsForReverseAuctionsSchema() {
  return z
    .object({
      sortColumn: z
        .enum([
          'status',
          'networkPointName',
          'tsoName',
          'quantityValue',
          'capacityCategory',
          'runtimeStart',
          'nextClose',
        ])
        .default('nextClose'),
      sortDirection: ascSortDirectionSchema,
    })
    .merge(getPaginationSchema({ pageSize: 5 }));
}
