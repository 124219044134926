import { FC, lazy, useMemo } from 'react';
import {
  LngOffersOverviewParams,
  OfferStatusParams,
} from 'src/apis/lng-marketing/types';
import { useLngMarketingRoot } from 'src/apis/lng-marketing/use-lng-marketing-root';
import { useLngOffers } from 'src/apis/lng-marketing/use-lng-offers';
import { PageSpinner } from 'src/components/spinner-container';
import { useLngOffersPageParams } from 'src/pages/lng/offers/overview/use-page-params';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(() => import('./page'));

export const LngOffers: FC = () => {
  const lngMarketingRoot = useLngMarketingRoot();
  const pageParams = useLngOffersPageParams();

  const params = useMemo(() => {
    const offersLink = lngMarketingRoot?.response?.data._links.getOffers;

    // due to BE implementation, when nothing is selected, we need to add all statuses in query params
    const status: Array<OfferStatusParams> = pageParams.value.status.length
      ? pageParams.value.status
      : [
          'PUBLISHED',
          'OPEN',
          'WAITING_FOR_NEXT_ROUND',
          'ALLOCATION_PENDING',
          'APPROVAL_PENDING',
          'ADDITIONAL_SERVICE_BOOKING_OPEN',
        ];
    if (offersLink && !pageParams.value.status.length) status.push('CREATED');

    return {
      sortBy: pageParams.value.sortBy,
      sortDirection: pageParams.value.sortDirection,
      limit: pageParams.value.pageSize,
      offset: pageParams.value.start,
      terminalId: pageParams.value.terminalId.map((terminal) => terminal.value),
      lsoOrganisationId: pageParams.value.lsoOrganisationId.map(
        (lso) => lso.value
      ),
      status,
      offerName: pageParams.value.offerName,
      offerBusinessId: pageParams.value.offerBusinessId,
      serviceRuntimeStart: pageParams.value.serviceRuntimeStart,
      serviceRuntimeEnd: pageParams.value.serviceRuntimeEnd,
      serviceType: pageParams.value.serviceType,
      ownOffersOnTop: pageParams.value.ownOffersOnTop,
    } satisfies LngOffersOverviewParams;
  }, [pageParams.value, lngMarketingRoot?.response]);

  const lngOffers = useLngOffers({
    lngMarketingRoot,
    params,
  });

  if (!lngOffers || !lngMarketingRoot) throw new Forbidden();

  if (!lngOffers.response || !lngMarketingRoot.response) return <PageSpinner />;

  return (
    <Page
      pageParams={pageParams}
      lngOffers={lngOffers}
      lngMarketingRoot={lngMarketingRoot}
    />
  );
};
